import React, { useRef, useEffect } from "react";
import "./css/Home.css";
import "./css/Popup.css";

import entre from "./image/entre_sidc.png";
import Second from "./Second";
import Rotate from "./Rotate";
import Liteabout from "./Liteabout";
import Projector from "./image/Projector.svg";

import Offer from "./Offer";
// import News from "./News";
import Footer from "./Footer";

import Navigation from "./Navigation";


const Home = () => {
  const ref = useRef(null);
  const refa = useRef(null);

  const fun = () => {
    document.getElementById("myModal").style.display = "block";
  };

  const func = () => {
    document.getElementById("myModal").style.display = "none";
  };

  
  useEffect(() => {
    setTimeout(() => {
      refa.current.click();
    }, 7000); //miliseconds
  }, []);

  return (
    <>
      <Navigation />
      <div className="relative text-center">
        <img className="imgpat pt-28 md:pt-10" src={Projector} alt="hero-img1" />
        <img className="imgs pt-24 md:pt-10" src={entre} alt="hero-img2" />
      </div>

      <Second />
      <Liteabout />
      <Rotate />
      {/* <Walloffame /> */}
      <br /><br />
      <Offer />
      
      {/* <News /> */}
      <Footer />
    </>
  );
};

export default Home;


