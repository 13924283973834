import a1 from "./image/a1.svg";
import a3 from "./image/a3.svg";
import a4 from "./image/a4.svg";
import a5 from "./image/a5.svg";
import a6 from "./image/a6.svg";

const detail = [
  {
    mainhead: "Who We Are",
    body: "We started off in 2015, with an aim to create an ambient atmosphere for all you different minds out there and provide exciting new opportunities to let your dreams evolve into realities and in the process make this world a better place.",
    image: a1,
  },
  {
    mainhead: "What do we do?",
    body: "Remember the last time you had a Eureka moment create something and you went on discussing it only to have your idea discarded as being 'impossible' or because you thought you didn't have the resources or expertise to do so? Well, we are here waiting for you and for everyone who wants to innovate.",
    image: a3,
  },
  {
    mainhead: "How we do it?",
    body: "We help to connect. So, let’s say you have a certain skill set e.g.designing, photography, content writing etc. So once you postyour profile with us, you become visible to the whole industrylooking for people like you. If their requirements get matched,you might land up with an assignment/internship/job with them.Amazing isn't it? Whether you have an idea or not, your spirit oflearning and contributing is all that we are looking for and value.",
    image: a4,
  },
  {
    mainhead: "What Does The SIIEC Do For Startups?",
    body: "The SIIEC is an incubator, and therefore, it does things like providing infrastructure, funding, mentorship, workspace and theoverall nurturing that a team/startup requires. Straight from theinception of an idea to the emergence of said idea into an endresult, the SIIEC coaches teams/startups through the entireprocess.",
    image: a5,
  },
  {
    mainhead: "Who can join us?",
    body: "YOU. The mere fact that you have taken the time to explore us is testimony enough that you have what we are looking for:aspirations. And trust us, we are here to nurture your aspirations.",
    image: a6,
  },
];

export default detail;
