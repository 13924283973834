import React, { useEffect, useState }  from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import "./css/Team.css";
import about1 from "./image/about1.png";
import { 
  Card, 
  CardHeader, 
  CardBody,
  SkeletonText,
  StackDivider,
  Heading,
  Box,
  Stack,
  Text,
  CardFooter,
  
 } from '@chakra-ui/react'


 import { AiOutlineMail, AiOutlineLinkedin } from 'react-icons/ai'

 import { BsFillPersonFill } from "react-icons/bs";

const Mentors = () => {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [emdata, setemdata] = useState([]);
  const [isemLoading, setIsemLoading] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      import('./ementors.json')
      .then((data)=>{
        setemdata(data.default);
        setIsemLoading(false);
      })
      .catch((err)=>{
        console.error('error importing ext mentors: ',err);
        setIsemLoading(false);
      });

    }, 2000)
  }, []);

  useEffect(() => {
    
    setTimeout(() => {
      import('./rmentors.json')
        .then((data) => {
          setJsonData(data.default);
          setIsLoading(false); 
        })
        .catch((error) => {
          console.error('Error importing JSON data: ', error);
          setIsLoading(false); 
        });
    }, 2000); 
  }, []);
  return (
    <>
    <Navigation />

        
        <div className="abouttop">
            <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="event3">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            Our Mentors
          </h3>
        </div>
        <section className='my-12'>
            {/* External mentors  */}
            <div className="text-primaryYellow font-bold text-3xl my-3 font-ubuntu text-center">
              <h1>External Mentors</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mx-auto w-full gap-6 md:gap-12 my-6 md:w-10/12">
            {isemLoading ? (
              <>
              <Stack className='mx-6'>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
              </Stack>
              </>
            ) : (
            emdata.map((person, index) => (
              <Card key={index} >
              <CardHeader className='  border-x border-t  border-primaryYellow rounded-t-md'>
                <Heading size='md' className="font-bold ">{person.name}</Heading>
              </CardHeader>
              <CardBody className='  border-x  border-primaryYellow '>
                <Stack divider={<StackDivider />} spacing='2'>
                  <Box>
                    <Text  fontSize='sm'>
                      {person.designation}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize='sm'>
                      {person.organisation}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
              <CardFooter className='shadow-md border-b border-x border-primaryYellow rounded-b-md'>
                <div className='flex flex-row'>
                  
                  <a href={person.linkedin} className='w-max text-primaryYellow'>
                      <AiOutlineLinkedin style={{width:'32px'}}/>     
                  </a>
                </div>
              </CardFooter>
            </Card>
            )) )}           
          </div>
        </section>
        <section className='my-12'>
          {/* Residents starts from here... */}
          <div className="text-primaryYellow font-bold text-3xl my-3 font-ubuntu text-center">
            <h1>Resident Mentors</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mx-auto w-full gap-6 md:gap-12 my-6 md:w-10/12">
            {isLoading ? (
              <>
              <Stack className='mx-6'>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
                <Box padding='6' boxShadow='lg' bg='white' w='85vw'>                
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                  <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                </Box>
              </Stack>
              </>
            ) : (
            jsonData.map((person) => (
              <Card key={person.index} >
              <CardHeader className='  border-x border-t  border-primaryYellow rounded-t-md'>
                <Heading size='md' className="font-bold ">{person.name}</Heading>
              </CardHeader>
              <CardBody className='  border-x  border-primaryYellow '>
                <Stack divider={<StackDivider />} spacing='2'>
                  <Box>
                    <Text  fontSize='sm'>
                      {person.designation}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize='sm'>
                      {person.department}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
              <CardFooter className='shadow-md border-b border-x border-primaryYellow rounded-b-md'>
                <div className='flex flex-row'>
                  <a href={`mailto:${person.emailid}`} className='w-max text-primaryYellow'>
                      <AiOutlineMail style={{width:'32px'}}/>     
                  </a>
                  <a href={person.profile} className='w-max text-primaryYellow'>
                      <BsFillPersonFill style={{width:'32px'}}/>     
                  </a>
                </div>
              </CardFooter>
            </Card>
            )) )}           
          </div>
        </section>
        <Footer />
    </>
  )
}

export default Mentors