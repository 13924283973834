import React from "react";

const Second = () => {
  return (
    <>
      <div className="w-full text-center my-10 flex flex-col md:flex-row items-center justify-evenly">
        <div>
          <p className="font-extrabold text-3xl mt-1 font-ubuntu">
            For Incubation
          </p>
          <div className="mt-5 mb-11">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://srmdei.accubate.app/ext/form/1600/1/apply"
            >
              {" "}
              <button className="button bg-primaryYellow px-4 py-3 rounded-md font-semibold text-xl">
                Apply Now
              </button>
            </a>
          </div>
        </div>

        <div>
          <p className="font-extrabold text-3xl mt-2 font-ubuntu">
            For Innovators
          </p>
          <div className="mt-8">
            <a
              className="button bg-primaryYellow px-4 py-3 rounded-md font-semibold text-xl"
              target="_blank"
              rel="noopener noreferrer"
              href="https://srmdei.accubate.app/ext/form/1870/1/apply"
            >
              Apply For Prototype Funding
            </a>
            <div className="w-full text-center mt-4">
              <p>supported by MOE's Institution's Innovation Council</p>
              <p>funded by NewGen IEDC SRM</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Second;
