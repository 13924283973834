import React from "react";
import Marquee from "react-fast-marquee";
import zero_meat from "./image/zeromeat.webp";
import workify from "./image/Workify-01.webp";
import weezy from "./image/WEEZY.webp";
import weber from "./image/Weber Mobility Logo.webp";
import think_metal from "./image/Think metal-01.webp";
import stemonc from "./image/Stemonc.webp";
import reliamotive_labs from "./image/Reliamotive labs-01.webp";
import nwb from "./image/NWB.webp";
import neur from "./image/Neur.webp";
import kreator from "./image/Kreator.webp";
import intakt from "./image/Intakt EV-01.webp";
import go_yatra from "./image/Go Yatra logo1.webp";
import fitsurance from "./image/Fitsurance.webp";
import climec from "./image/Climec Labs White Background.webp";
import cambronics from "./image/Cambrionics.webp";
import brainwaves from "./image/Brainwaves.webp";
import aptitute_buster from "./image/Aptitude buster-01-01.webp";
import airdonex from "./image/Airdonex-01.webp";
import reflow from "./image/reflow.png";
import vayut from "./image/vayut.jpeg";
import waveform from "./image/waveform.png";
import hearnatal from "./image/HN.avif";
import freaklearn from "./image/freaklearn.png";

import ZED from "./image/ZED.webp";
import yogya from "./image/Yogya.webp";
import sure_local from "./image/Sure Local.webp";
import RIZEL from "./image/RIZEL.webp";
import paper_plane from "./image/Paper Plane-01.webp";
import mithril from "./image/Mithril Energy.webp";
import mczone from "./image/McZone.webp";
import closerlook from "./image/Closerlook.webp";
import torus from "./image/torus.png";
import monkwish from "./image/monkwish.jpg";
import bversity from "./image/bversity.png";

import pb1 from "./image/pb1.png";
import pb2 from "./image/pb2.png";
import pb3 from "./image/pb3.png";
import pb4 from "./image/pb4.png";
import pb5 from "./image/pb5.png";
import pb6 from "./image/pb6.png";
import pb7 from "./image/pb7.png";
import pb8 from "./image/pb8.png";
import pb9 from "./image/pb9.png";
import pb10 from "./image/pb10.png";
import pb11 from "./image/pb11.png";
import pb12 from "./image/pb12.png";
import pb13 from "./image/pb13.png";
import pb14 from "./image/pb14.png";


const Rotate = () => {
  return (
    <>
      <div>
        <p className="w-full text-center">
          <p className="font-bold text-4xl mt-20 mb-14 font-ubuntu">Our Current Startups</p>
        </p>
        <Marquee style={{ marginBottom: "0.5rem", zIndex: "0" }} speed={25} pauseOnHover>
          {[
            { image: zero_meat, url: "https://www.linkedin.com/company/zero-meat/?originalSubdomain=in" },
            { image: workify, url: "https://www.linkedin.com/company/workifyindia/about/" },
            { image: weezy, url: "https://weezy.in/" },
            { image: weber, url: "https://wbr.mobi/" },
            { image: stemonc, url: "https://www.stemonc.com/" },
            { image: reliamotive_labs, url: "https://reliamotivelabs.tech/" },
            { image: nwb, url: "" },
            { image: kreator, url: "http://kreator3d.com/" },
            { image: intakt, url: "https://www.intaktev.com/" },
            { image: go_yatra, url: "" },
            { image: fitsurance, url: "https://fitsurance.in/" },
            { image: climec, url: "http://www.climeclabs.com/" },
            { image: cambronics, url: "https://www.cambrionics.com/" },
            { image: brainwaves, url: "http://brainwavesinc.tech/" },
            { image: aptitute_buster, url: "" },
            { image: reflow, url: "https://reflowtech.in" },
            { image: vayut, url: "https://www.linkedin.com/company/vayutsocial/about/" },
            { image: waveform, url: "https://www.waveformlabs.in" },
            { image: hearnatal, url: "https://hearnatal.info/" },
            { image: freaklearn, url: "https://freaklearn.com/" },
            { image: airdonex, url: "http://www.airdonex.com/" },
          ].map((item) => {
            return (
              <a target="_blank" href={item.url} rel="noopener noreferrer">
                <img
                  alt={item.url}
                  src={item.image}
                  style={{ flex: "none", height: "4rem", marginRight: "4rem" }}
                />
              </a>
            );
          })}
        </Marquee>
      </div>
      <br />
      <div>
        <p className="w-full text-center">
          <p className="font-bold text-4xl mt-20 mb-14 font-ubuntu">Our Graduated Startups</p>
        </p>
        <Marquee style={{ marginBottom: "0.5rem", zIndex: "0" }} speed={30} pauseOnHover>
          {[

            { image: ZED, url: "https://www.cambrionics.com/" },
            { image: yogya, url: "https://yogya.ai/" },
            { image: sure_local, url: "https://www.surelocal.in/#/" },
            { image: RIZEL, url: "https://www.rizelautomotive.com/" },
            { image: paper_plane, url: "" },
            { image: mithril, url: "" },
            { image: mczone, url: "https://www.mczone.in/McZone" },
            { image: closerlook, url: "" },
            { image: torus, url: "https://www.torusrobotics.com" },
            { image: monkwish, url: "https://www.monkwish.com" },
            { image: think_metal, url: "http://thinkmetal.in/" },
            { image: neur, url: "https://www.neurindustries.com/" },
            { image: bversity, url: "https://bversity.io" },
          ].map((item) => {
            return (
              <a target="_blank" href={item.url} rel="noopener noreferrer">
                <img
                  alt={item.url}
                  src={item.image}
                  style={{ flex: "none", height: "4rem", marginRight: "4rem" }}
                />
              </a>
            );
          })}
        </Marquee>
      </div>
      <br />
      <div>
        <p className="w-full text-center">
          <p className="font-bold text-4xl mt-20 mb-14 font-ubuntu">Our Partners</p>
        </p>
        <Marquee style={{ marginBottom: "0.5rem", zIndex: "0" }} speed={30} pauseOnHover>
          {[

            { image: pb1, url: "https://www.ediindia.org/" },
            { image: pb2, url: "https://iedc.srmiiec.com/" },
            { image: pb3, url: "https://jacobsinstitute.berkeley.edu/" },
            { image: pb4, url: "https://scet.berkeley.edu/" },
            { image: pb5, url: "https://www.kingston.ac.uk/" },
            { image: pb7, url: "https://www.intellectdesign.com/" },
            { image: pb8, url: "https://www.thechennaiangels.com" },
            { image: pb9, url: "https://chennai.tie.org/" },
            { image: pb10, url: "https://mic.gov.in/" },
            { image: pb11, url: "https://iic.mic.gov.in/" },
            { image: pb12, url: "https://www.tees.ac.uk/" },
            { image: pb13, url: "https://msme.gov.in/" },
            { image: pb14, url: "https://dst.gov.in/" },
            { image: "https://startuptn.in/wp-content/uploads/2023/01/Startuptn-logo.png", url: "https://startuptn.in" },
            { image: "https://media.licdn.com/dms/image/C510BAQH46Ym5a6Xjow/company-logo_200_200/0/1519905852339?e=2147483647&v=beta&t=9rdmH1KnLn_iHJ_8V2WHS4lowQPr37BBLG7mdrkdF4c", url: "https://www.d-thinking.com" },
            { image: "https://www.startupindia.gov.in/content/dam/invest-india/newhomepage/Logo1.png", url: "https://www.startupindia.gov.in" },
            { image: "https://www.thecampusangels.com/wp-content/uploads/2023/03/MicrosoftTeams-image-3-2-150x150.jpg", url: "https://www.thecampusangels.com" },
            { image: "https://images.crunchbase.com/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ukbwra7ob3ljfhihkgok", url: "https://jajalmedical.com" },
            { image: "https://bridgebharatcouncil.org/assets/images/bridge-bharat-logo-pdf-modified-96x69.png", url: "https://bridgebharatcouncil.org" },
            { image: "https://www.stpinext.in/wp-content/uploads/2019/11/AIC-STPINEXT-INITIATIVES-logo-7-A-01-150x150.png", url: "https://www.stpinext.in" },
            { image: "http://pccoeciil.com/assets/img/logo.png", url: "http://pccoeciil.com" },
            { image: "https://isba.in/wp-content/uploads/2020/01/logo-cropped.png", url: "https://isba.in" },
            { image: "https://media.licdn.com/dms/image/D560BAQG2ZDhHj0NGxQ/company-logo_200_200/0/1689680584203?e=2147483647&v=beta&t=PiHSndDHRznONts68VMFLjetCRZSwCrMsVWZwkRK29g", url: "https://www.tnthub.org" },
            { image: "https://www.rkdewan.com/images/logo/logo-n.png", url: "https://www.rkdewan.com" },
            { image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Vakilsearch-logo.svg/1200px-Vakilsearch-logo.svg.png?20181018115356", url: "https://vakilsearch.com" },
          ].map((item) => {
            return (
              <a target="_blank" href={item.url} rel="noopener noreferrer">
                <img
                  alt={item.url}
                  src={item.image}
                  style={{ flex: "none", height: "4rem", marginRight: "4rem" }}
                />
              </a>
            );
          })}
        </Marquee>
      </div>
    </>
  );
};

export default Rotate;


