import { BsLinkedin } from "react-icons/bs";
import { MdMail } from "react-icons/md";

export default function Teamcard({ props }) {
  return (
    <>
      <div className="rounded-md border border-primaryYellow text-center py-4">
        <img
          className="h-60 mx-auto mb-4 rounded-md"
          src={props.image}
          alt="team-card-img"
        />
        <p className="text-bold text-lg md:text-2xl px-2">{props.name}</p>
        <p className="text-bold text-sm md:text-md px-2">{props.designation}</p>

        <div className="flex w-full items-center justify-center my-2">
          {props.linkedin && (
            <>
              <a
                href={props.linkedin}
                target={"_blank"}
                rel="noopener noreferrer"
                className="rounded-full bg-primaryYellow p-2 mx-2"
              >
                <BsLinkedin size={25} />
              </a>
            </>
          )}
          {props.email && (
            <>
              <a
                href={`mailto:${props.email}`}
                target={"_blank"}
                rel="noopener noreferrer"
                className="rounded-full bg-primaryYellow p-2 mx-2"
              >
                <MdMail size={25} />
              </a>
            </>
          )}
        </div>
      </div>
    </>
  );
}
