import { React, useState } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import { 
  Card, 
  CardBody, 
  CardFooter,
  Image, 
  Stack, 
  Heading, 
  Text, 
  Divider, 
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";
import "./css/Event-child.css";


const images = [
  "https://i.postimg.cc/JnxSg2K5/1.jpg",
  "https://i.postimg.cc/HsVFZ3Mb/2.jpg",
  "https://i.postimg.cc/Y2XJzTvp/3.jpg",
  "https://i.postimg.cc/85bVG5kW/4.jpg",
  "https://i.postimg.cc/85bVG5kW/4.jpg",
  "https://i.postimg.cc/L50w4gcq/6.jpg",
]

const images1 = [
  "https://i.postimg.cc/x8nnvJ5p/24-aug.jpg",
  "https://i.postimg.cc/c41d63rr/25-aug.jpg",
  "https://i.postimg.cc/cJ3SPM55/26-aug.jpg",
  "https://i.postimg.cc/cJ3SPM55/26-aug.jpg",
  "https://i.postimg.cc/c1Bssh1p/28-aug.jpg",
  "https://i.postimg.cc/K8wb9sQM/Boot-camp.jpg",
]

const images2 = [
  "https://i.postimg.cc/c1ckjkqc/341784418-1263287667935267-2635992237283668162-n.jpg",
  "https://i.postimg.cc/DZP690Nz/341914804-190791436650191-5396062011156337693-n.jpg",
  "https://i.postimg.cc/pXXctQRM/341915107-186729644217604-6282027057412209856-n.jpg",
  "https://i.postimg.cc/ZK77Y95B/341917189-1295361131190266-8232316242066045987-n.jpg",
  "https://i.postimg.cc/0j5nPxg0/341930238-258121843319287-6099853005737352933-n.jpg",
  "https://i.postimg.cc/mkTXkC9M/341933541-3485972365012538-5970762926090938533-n.jpg",
  "https://i.postimg.cc/P5C6CtC6/342069776-1599225307228501-1022935094672818083-n.jpg",
  "https://i.postimg.cc/1tQM7wJT/342071204-526277482816625-2539994687411979014-n.jpg",
  "https://i.postimg.cc/2Sg0vPvC/342072769-2565459710270868-5741908031543789492-n.jpg",
  "https://i.postimg.cc/HnS2LKdD/342113848-2146822038861757-5296869642706659366-n.jpg",
  "https://i.postimg.cc/BnPgqMzk/342169010-2543651769118202-6412406247435967348-n.jpg",
  "https://i.postimg.cc/Lsz3Ph2V/342203009-218158334162638-4948527092622612719-n.jpg",
  "https://i.postimg.cc/Gp1kbrGT/342214204-803668197284419-8330760239352035788-n.jpg",
  "https://i.postimg.cc/9MbPxS4R/342219597-759762035779619-8732749117572475572-n.jpg",
  "https://i.postimg.cc/Vs7BnxQ2/342224908-1370965703637064-1150225877941437509-n.jpg",
  "https://i.postimg.cc/PrRysBS1/342275483-198644066264395-304420143084783375-n.jpg",
  "https://i.postimg.cc/cCJRPmYD/342367714-2407299149427626-2624718416515820694-n.jpg",
  "https://i.postimg.cc/LsfNVNBL/342368859-1614895159026976-8868711593660507912-n.jpg",
  "https://i.postimg.cc/66zzz7Rj/342369080-551932513741599-7047866910538093480-n.jpg",
  "https://i.postimg.cc/Rhb7mHLv/342534083-184750794398077-1795795098818718205-n.jpg",
  "https://i.postimg.cc/BbtFmv9k/342552979-174984055488136-492220391555735670-n.jpg",
  "https://i.postimg.cc/xTC5Z030/342562702-186383473809401-955276355818999198-n.jpg",
  "https://i.postimg.cc/Sx960j2p/342567235-2184154205106455-2312653608511386844-n.jpg",
  "https://i.postimg.cc/rmMCNcp2/342591892-1382005819397632-4105207820423681435-n.jpg",
  "https://i.postimg.cc/YqhN3dt0/342720218-245218867999582-6125246861032334270-n.jpg",
  "https://i.postimg.cc/gj5ZQPGJ/342734016-766581445017287-4382294500423370597-n.jpg",
  "https://i.postimg.cc/cHxw8mDs/342736517-786553172620045-3065675975622436592-n.jpg",
  "https://i.postimg.cc/MGb1T4mP/342868518-567341798873673-4732688078736498658-n.jpg",
  "https://i.postimg.cc/cHFY7njf/343062582-197428749762372-9001377597154853997-n.jpg",
  
]

const Bootcamp = () => {
    const handleShareClick = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              url: "https://www.srmdei.com/event/bc",
            });
            console.log('Link shared successfully!');
          } else {
            
            console.log('Web Share API is not supported.');
          }
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      }

      
      const [toggler, setToggler] = useState(false);
      const [toggler1, setToggler1] = useState(false);
      const [toggler2, setToggler2] = useState(false);
  return (
    <>
        <Navigation/>
            <main>
            <section className="gradient-custom customboot">
                <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
                    Bootcamp
                </h3>
            </section>
            <div
                className=" py-6 flex flex-row items-center justify-center"
            >
                <div
                    className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
                >
                <div className="w-full flex flex-row justify-between">
                    <h1 className="text-3xl font-ubuntu font-bold m-3">
                    Boot Camp
                  </h1>
                  <button
                        style={{ background: "white" }}
                        onClick={handleShareClick}
                    >
                        <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
                    </button>
                </div>
                <div className="w-full flex flex-wrap gap-8 my-5">
                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Entrepreneurship Boot Camp
                    </h1>
                    <p>Edition 1 - Entrepreneurship Boot Camp </p>
                    <p>From 13th to 16th September 2019 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1bNF5Q2B6bUEnvcMbXZce6pf6CmAKZskP?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}


                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Entrepreneurship Boot Camp</Heading>
                        <Text>
                          Edition 1 - Entrepreneurship Boot Camp 
                          From 18th to 22nd April 2023 
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler2(!toggler2)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler2}
                        sources={images2}
                      />
                      </CardFooter>
                    </Card>


                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Entrepreneurship Boot Camp</Heading>
                        <Text>
                          Edition 3 - Entrepreneurship Boot Camp 
                          From 28th April to 1st May 2022
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Popover>
                        <PopoverTrigger>
                        <Button>
                          View Gallery
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>OOPPS!!!</PopoverHeader>
                          <PopoverBody>Unfortunately There are no Images for this event!</PopoverBody>
                        </PopoverContent>
                      </Popover>
                      
                    </CardFooter>
                  </Card>

                  

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Entrepreneurship Boot Camp
                    </h1>
                    <p>Edition 2 - Entrepreneurship Boot Camp </p>
                    <p>From 18th to 21st November 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/11z3Tbwu28NFz4rRBizEe9ovoQM38OqMa?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}

                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Entrepreneurship Boot Camp</Heading>
                        <Text>
                          Edition 2 - Entrepreneurship Boot Camp 
                          From 18th to 21st November 2021
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                    <Button onClick={() => setToggler1(!toggler1)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler1}
                        sources={images1}
                      />
                    </CardFooter>
                  </Card>

                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Entrepreneurship Boot Camp</Heading>
                        <Text>
                          Edition 1 - Entrepreneurship Boot Camp 
                          From 13th to 16th September 2019 
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler(!toggler)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler}
                        sources={images}
                      />
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Entrepreneurship Boot Camp
                    </h1>
                    <p>Edition 3 - Entrepreneurship Boot Camp </p>
                    <p>From 28th April to 1st May 2022 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1nZmDjY1y-gNREFyzJIGPk6muqPyET9lA?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  

                </div>
              </div>
            </div>
            </main>
        <Footer/>
    </>
  )
}

export default Bootcamp