import React from "react";
import "./css/Idea.css";
import darshil from "./image/guy_build.png";

const Idea = () => {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row my-14 px-8">
        <div className="w-full md:w-1/2">
          <p className="text-3xl md:text-6xl font-bold px-2 py-0 md:py-2">
            Working on something
          </p>
          <p className="text-3xl md:text-6xl font-bold px-2 py-0 md:py-2 transition-all text-primaryYellow hover:drop-shadow-2xl hover:border-b-2 hover:border-primaryYellow hover:shadow-primaryYellow">
            INTERESTING?
          </p>
          <p className="text-3xl md:text-4xl font-medium px-2 py-0 md:py-2">
            Let's talk!
          </p>

          <div className="flex flex-col">
            <form
              target="_blank"
              action="https://formsubmit.co/gm.dei@srmist.edu.in"
              method="POST"
            >
              <input
                type="text"
                placeholder="Your Name"
                name = "name"
                className="w-full md:w-2/3 p-2 m-2 border-primaryYellow rounded-md border focus:outline-none"
              />
              <input
                type="text"
                name = "email"
                placeholder="Your Email ID"
                className="w-full md:w-2/3 p-2 m-2 border-primaryYellow rounded-md border focus:outline-none"
              />
              <textarea
                name="comments"
                className="w-full md:w-2/3 p-2 m-2 border-primaryYellow rounded-md border focus:outline-none"
                placeholder="Hey! I want to connect regarding..."
                rows="7"
                cols="1"
              ></textarea>
              <button
                type="submit"
                className="bg-primaryYellow p-2 mx-auto md:ml-2 w-2/3 md:w-1/3 hover:bg-opacity-20 border-2 shadow-sm hover:border-primaryYellow transition-all "
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        <div className="w-1/2 hidden md:block">
          <img className="hidden md:flex w-auto mx-auto" src={darshil} alt="idea-img" />
        </div>
      </div>
    </>
  );
};

export default Idea;
