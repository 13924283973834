import { React, useState } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import { 
  Card, 
  CardBody, 
  CardFooter,
  Image, 
  Stack, 
  Heading, 
  Text, 
  Divider, 
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";
import "./css/Event-child.css";


const images = [
  "",
  "",
  "",
]


const Webinar = () => {
    const handleShareClick = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              url: "https://www.srmdei.com/event/wb",
            });
            console.log('Link shared successfully!');
          } else {
            
            console.log('Web Share API is not supported.');
          }
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      }
      const [toggler, setToggler] = useState(false);
      const [toggler1, setToggler1] = useState(false);
      const [toggler2, setToggler2] = useState(false);
  return (
    <>
        <Navigation/>
            <main>
            <section className="gradient-custom ">
                <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
                    Webinar and Seminars
                </h3>
            </section>
            <div
                className=" py-6 flex flex-row items-center justify-center"
            >
                <div
                    className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
                >
                <div className="w-full flex flex-row justify-between">
                    <h1 className="text-3xl font-ubuntu font-bold m-3">
                    Webinar and Seminars
                  </h1>
                  <button
                        style={{ background: "white" }}
                        onClick={handleShareClick}
                    >
                        <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
                    </button>
                    </div>

                <div className="w-full flex flex-wrap gap-8 md:gap-10 my-5">
                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Introduction to Design Thinking Approach
                    </h1>
                    <p>by Dr. Anbu Rathinavel </p>
                    <p>on 19 May 2020 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1WB9Pq4vaJyVI9GooB9HeQOWgYvS84lGa?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Introduction to Design Thinking Approach</Heading>
                        <Text>
                          by Dr. Anbu Rathinavel
                          on 19 May 2020 
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Popover>
                        <PopoverTrigger>
                        <Button>
                          View Gallery
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>OOPPS!!!</PopoverHeader>
                          <PopoverBody>Unfortunately There are no Images for this event!</PopoverBody>
                        </PopoverContent>
                      </Popover>
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Awareness on Copyrights & Design
                    </h1>
                    <p>
                      by Adv. A.K. Balaji , Patent Attorney, R.K.Devan 7 Co.,{" "}
                    </p>
                    <p>on 12 July 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1WB9Pq4vaJyVI9GooB9HeQOWgYvS84lGa?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Awareness on Copyrights & Design</Heading>
                        <Text>
                          by Adv. A.K. Balaji, Patent Attorney, R.K.Devan & Co.<br/>
                          on 12 July 2021
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Popover>
                        <PopoverTrigger>
                        <Button>
                          View Gallery
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>OOPPS!!!</PopoverHeader>
                          <PopoverBody>Unfortunately There are no Images for this event!</PopoverBody>
                        </PopoverContent>
                      </Popover>
                      </CardFooter>
                    </Card>
{/*                   

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Significance of IP in the START-UP Road Map{" "}
                    </h1>
                    <p>
                      by G. Arun Kumar, IP Attorney, Arun Associates, Chennai{" "}
                    </p>
                    <p>on 26 April 2022 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1WB9Pq4vaJyVI9GooB9HeQOWgYvS84lGa?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Significance of IP in the START-UP Road Map</Heading>
                        <Text>
                          by G. Arun Kumar, IP Attorney, Arun Associates, Chennai<br/>
                          on 26 April 2022 
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Popover>
                        <PopoverTrigger>
                        <Button>
                          View Gallery
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>OOPPS!!!</PopoverHeader>
                          <PopoverBody>Unfortunately There are no Images for this event!</PopoverBody>
                        </PopoverContent>
                      </Popover>
                      </CardFooter>
                    </Card>

                </div>
                </div>
                </div>
            </main>
        <Footer/>
    </>
  )
}

export default Webinar