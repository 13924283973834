import { React, useState, useEffect } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";

import{
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Box,
  Text,
  CardFooter,
  Button,
} from '@chakra-ui/react';

import "./css/Event-child.css";


const Workshop = () => {
    const handleShareClick = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              url: "https://www.srmdei.com/event/ws",
            });
            console.log('Link shared successfully!');
          } else {
            
            console.log('Web Share API is not supported.');
          }
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      }

      const [jsonData, setJsonData] = useState([]);
    useEffect(() => {
      
      setTimeout(() => {
        import('./workshop.json')
          .then((data) => {
            setJsonData(data.default);            
          })
          .catch((error) => {
            console.error('Error importing JSON data: ', error);
          });
      }, 1000); 
    }, []);

  return (
    <>
    <Navigation/>
    <main>
        <section className="gradient-custom customwork">
            <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
                Workshop 
            </h3>
        </section>
            <div
                className=" py-6 flex flex-row items-center justify-center"
            >
                <div
                    className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
                >
                <div className="w-full flex flex-row justify-between">
                    <h1 className="text-3xl font-ubuntu font-bold m-3">
                    Workshop 
                  </h1>
                  <button
                        style={{ background: "white" }}
                        onClick={handleShareClick}
                    >
                        <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
                    </button>
                
                </div>
                <div className="w-full flex flex-wrap gap-8 md:gap-10 my-5">
            {jsonData.map((event, index) => (
            <div>
              <Card maxW='sm' h='sm' key={index} >
              <CardHeader className='  border-x border-t mt-4 border-primaryYellow rounded-t-md'>
                <Heading size='md' className="font-bold ">{event.title}</Heading>
              </CardHeader>
              <CardBody className='  border-x  border-primaryYellow '>
                <Stack divider={<StackDivider />}mt='2' spacing='2'>
                  <Box>
                    <Text  fontSize='sm'>
                      {event.edition}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize='sm'>
                      {event.date}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
              <CardFooter className='shadow-md border-b border-x border-primaryYellow rounded-b-md'>
                <Button onClick={()=>{window.open(event.gallerylink)}}>Gallery</Button>
              </CardFooter>
            </Card>
            </div>
            ))}

          </div>
                {/* <div className="w-full flex flex-col my-5">
                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Patent Drafting Workshop
                    </h1>
                    <p>by Mr. Arun Venkatraman, Founder Invenk Solutions. </p>
                    <p>on 05 January 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1GZ18AxhOSlMQ7H9ap0H6dJoDLYfXf_Vd?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Patent School Series 1, Session 1 : Idea Generation and
                      Patent-ability Aspect Analysis
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 03 February 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1NvhEu7lH0STz_Fz29_xT876X8E2t-L60?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">Startup Financing</h1>
                    <p>
                      by Mr. Yagna Raman, Founder Unthink Ventures and Mr.
                      Selvam Sundaramurthy, Co-Founder Dot Architecture and
                      Design Studio{" "}
                    </p>
                    <p>on 20 February 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Patent School Series 1 , Session 2 : Patent Search and
                      Drafting{" "}
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 05 March 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      IPR and Startup Policy for the Department of Mechanical
                      Engineering
                    </h1>
                    <p>
                      by Mr. Vijay Rathan Linga, Techno Legal Advisor, SIIEC,
                      SRMIST.{" "}
                    </p>
                    <p>on 10 September 2021 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      IPR and Startup Policy for the Department of Mechanical
                      Engineering
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 25 August 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Patent School Series 2 , Session 2 : Hands On Training on
                      Patent Searching
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 01 September 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Patent School Series 2 , Session 3 : My Patent - My
                      Drafting
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 08 September 2021</p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      {" "}
                      Patent School Workshop Series - Edition 3
                    </h1>
                    <p>
                      by Dr. A Balaji Ganesh, Dean (R&D), Velammal Engineering
                      College{" "}
                    </p>
                    <p>on 7th - 9th April 2022 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1ND1THIvOhWiPo_jLKMq0lP2jD_wiM4KL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Opportunities for Innovation and Startups in Defence
                      Sector
                    </h1>
                    <p>
                      by Dr. T. K. Varadarajan, Nodal officer - DGQA
                      Facilitation Cell, CODISSIA{" "}
                    </p>
                    <p>on 11 February 2022 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/15eD5Ht-4gCoJ2FMhh4ClgNWWPrItEFkG?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      {" "}
                      IPR and Startup Policy" for the Department of Mechanical
                      Engineering{" "}
                    </h1>
                    <p>
                      by Mr. Vijay Rathan Linga, Techno Legal Advisor, SIIEC,
                      SRMIST.{" "}
                    </p>
                    <p>on 08 October 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/15eD5Ht-4gCoJ2FMhh4ClgNWWPrItEFkG?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      {" "}
                      IPR and Startup Policy" for the Department of Mechanical
                      Engineering{" "}
                    </h1>
                    <p>
                      by Mr. Vijay Rathan Linga, Techno Legal Advisor, SIIEC,
                      SRMIST.{" "}
                    </p>
                    <p>on 13 September 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/15eD5Ht-4gCoJ2FMhh4ClgNWWPrItEFkG?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      PR and Startup Policy" for the Faculty members and
                      Research Scholars of Aerospace Department
                    </h1>
                    <p>
                      by Mr. Vijay Rathan Linga, Techno Legal Advisor, SIIEC,
                      SRMIST.{" "}
                    </p>
                    <p>on 07 September 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/15eD5Ht-4gCoJ2FMhh4ClgNWWPrItEFkG?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      IPR and Startup Policy" for the Faculty members and
                      Research Scholars of Mechatronics Department
                    </h1>
                    <p>
                      by Mr. Vijay Rathan Linga, Techno Legal Advisor, SIIEC,
                      SRMIST.{" "}
                    </p>
                    <p>on 03 September 2021 </p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1UCGQCKCfTQC9twAjgBqXhcQZiNhlnvhy?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div>
                </div> */}
            </div> 
            </div>           
    </main>
    <Footer/>
    </>
  )
}

export default Workshop
