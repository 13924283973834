import React from "react";
import "./css/About.css";
import about1 from "./image/about1.png";

// import News from "../js/News";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Aboutcard from "./Aboutcard";
import detail from "./aboutDetails";
import Idea from "./Idea";
import Contact from "./Contact";

const About = () => {
  return (
    <>
      <Navigation />

      <div className="about">
        <div className="abouttop">
          <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="event3">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            About.
          </h3>
        </div>

        <div className="flex flex-col">
          {detail.map((item) => {
            return <Aboutcard props={item} />;
          })}
        </div>
      </div>
      {/* <News /> */}
      <Idea />
      <Contact frameNumber={0}/>

      <Footer />
    </>
  );
};

export default About;
