import React from "react";
import "./css/Footer.css";
import footer_logo from "./image/sidc_logo.png";
import instagram from "./image/instagram.png";
import facebook from "./image/facebook.png";
import linkdin from "./image/linkdin.png";
import gmail from "./image/gmail.png";
import twitter from "./image/twitter.png";

import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsMailbox,
  BsTwitter,
} from "react-icons/bs";

import { HiOutlineMail } from "react-icons/hi";

export const Footer = () => {
  return (
    <div>
      <div className="mainfooter">
        <div id="mfooter">
          <div className="f1">
            <img
              src={footer_logo}
              alt="siiec-logo"
              className="mx-auto rounded-full w-32 md:w-38"
            />
          </div>
          <h3 className="fhead">
            Directorate of <br></br>Entrepreneurship & <br></br>Innovation (DEI) 
          </h3>
          <div className="f2">
            <h4 className="text-primaryYellow font-ubuntu font-bold">Information</h4>
            <ul id="ftxt">
              <li>
                <a href="./about">About Us</a>
              </li>
              <li>
                <a href="./event">Events</a>
              </li>
              <li>
                <a href="./team">Our Team</a>
              </li>
            </ul>
          </div>
          <div className="f3">
            <h4 className="text-primaryYellow font-ubuntu font-bold">Helpful Links</h4>
            <ul id="ftxt">
              <li>
                <a href="./events">Events</a>
              </li>

              <li>
                <a href="https://fablab.srmiiec.com">Fablab</a>
              </li>
            </ul>
          </div>
          <div className="f4">
            <h4 className="text-primaryYellow font-ubuntu font-bold">Our Social Network</h4>
            <p id="ffollow">Follow us here to get news first in the Market!</p>
            <div className="flex flex-row justify-between my-4">
              <a
                className="folink"
                href="https://www.instagram.com/srmiiec/"
                target="_blank"
              >
                <BsInstagram size={35} style={{ color: "white" }} />
              </a>
              <a
                className="folink"
                href="https://www.facebook.com/srmiic/"
                target="_blank"
              >
                <BsFacebook size={35} style={{ color: "white" }} />
              </a>
              <a
                className="folink"
                href="https://www.linkedin.com/school/srmiic/mycompany/"
                target="_blank"
              >
                <BsLinkedin size={35} style={{ color: "white" }} />
              </a>
              <a
                className="folink"
                href="https://twitter.com/SRM_DEI"
                target="_blank"
              >
                <BsTwitter size={35} style={{ color: "white" }} />
              </a>
              <a
                className="folink"
                href="mailto:manager.siic@srmist.edu.in"
                target="_blank"
              >
                <HiOutlineMail size={35} style={{ color: "white" }} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="fcopyright">
        <div style={{ marginLeft: "75px", paddingBottom: "10px" }}>
          &#169;Copyright <strong>DEI</strong> 2023. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};
export default Footer;
