import { React, useState, useEffect } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import { 
  Card, 
  CardBody, 
  CardFooter,
  // Image, 
  CardHeader, 
  StackDivider,
  Box,
  Stack, 
  Heading, 
  Text, 
  // Divider, 
  Button,
  // Popover,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverHeader,
  // PopoverBody,
  // PopoverFooter,
  // PopoverArrow,
  // PopoverCloseButton,
  // PopoverAnchor,
  
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";
import "./css/Event-child.css";



const TriumphTalk = () => {

  
    const handleShareClick = async () => {
      try {
        if (navigator.share) {
          await navigator.share({
            url: "https://www.srmdei.com/event/tt",
          });
          console.log('Link shared successfully!');
        } else {
          
          console.log('Web Share API is not supported.');
        }
      } catch (error) {
        console.error('Error sharing link:', error);
      }
    }
    
    const [jsonData, setJsonData] = useState([]);
    useEffect(() => {
      
      setTimeout(() => {
        import('./tt.json')
          .then((data) => {
            setJsonData(data.default);            
          })
          .catch((error) => {
            console.error('Error importing JSON data: ', error);
          });
      }, 1000); 
    }, []);
  
  return (
    <>
    <Navigation/>
    <main>
        <section className="gradient-custom">
          <h3 className="animate-character absolute text-3xl md:text-6xl font-ubuntu mt-6">
            Triumph Talks
          </h3>
        </section>
        
      <div
          className=" py-6 flex flex-col items-center justify-center"
        >
          <div
            className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
          >
            <div className="w-full flex flex-row justify-between">
              <h1 className="text-3xl font-ubuntu font-bold m-3">
                Triumph Talk
              </h1>
              <button
                style={{ background: "white" }}
                onClick={handleShareClick}
              >
                <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
              </button>
            </div>
            <div className="w-full flex flex-wrap gap-8 md:gap-10 my-5">
            {jsonData.map((event, index) => (
            <div>
              <Card maxW='sm' key={index} >
              <CardHeader className='  border-x border-t mt-4 border-primaryYellow rounded-t-md'>
                <Heading size='md' className="font-bold ">{event.title}</Heading>
              </CardHeader>
              <CardBody className='  border-x  border-primaryYellow '>
                <Stack divider={<StackDivider />}mt='6' spacing='3'>
                  <Box>
                    <Text  fontSize='sm'>
                      {event.edition}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize='sm'>
                      {event.date}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
              <CardFooter className='shadow-md border-b border-x border-primaryYellow rounded-b-md'>
                <Button onClick={()=>{window.open(event.gallerylink)}}>Gallery</Button>
              </CardFooter>
            </Card>
            </div>
            ))}

          </div>
        </div>
              {/* <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 1</h1>
                <p>
                  Edition 1 - by Dr. Sandeep Sancheti, Former Vice
                  Chancellor, SRMIST{" "}
                </p>
                <p>on 25 February 2019 </p>
                <div className="w-full flex items-end justify-end">
                  <button  className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1UCGQCKCfTQC9twAjgBqXhcQZiNhlnvhy?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 2</h1>
                <p>
                  Edition 2 - by Dr. C. Muthamizhchelvan,Vice Chancellor,
                  SRMIST{" "}
                </p>
                <p>on 13 March 2019 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1lYzo4reKeHeXWunOWugm8Scs3-aMGV2T?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 3</h1>
                <p>
                  Edition 3 - by Mrs. Vandhana Ramanathan, Co-founder,
                  Wsquard{" "}
                </p>
                <p>on 04 April 2019 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1WAqxY1wdwFwiVAbFkrvL5kGGgmUreKoS?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 4</h1>
                <p>
                  Edition 4 - by Dr. Ikhlaq Sidhu, Chief Scientist and
                  Faculty Director, UC Berkeley{" "}
                </p>
                <p>on 22 April 2019 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1spg9NSg9w40sITFRsm7xj_7ozfEkA-SU?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 5</h1>
                <p>
                  Edition 5 - by Mr. Chandran Krishnam, CEO , The Chennai
                  Angels{" "}
                </p>
                <p>on 11 January 2020 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1uNlGvmASSVhxS-OOnA2XwHDOlQRYizeU?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 6</h1>
                <p>
                  Edition 6 - by Mr. Hrishikesh Datar, Founder and CEO,
                  Vakilsearch.com{" "}
                </p>
                <p>on 28 May 2021 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1xwQjIWDNdTyzBtzSh7Mzs5fFB-0CQLJb?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 7</h1>
                <p>
                  Edition 7 - by Shivangi Nadkarni, Co- Founder and CEO,
                  Aarka{" "}
                </p>
                <p>on 6 August 2021 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1jmuJEBmix6OJcC46qXZdFJb1YcJvVgAi?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div>
              <div className="rounded border-primaryYellow border p-3 my-2">
                <h1 className="text-lg font-bold">Triumph Talk 8</h1>
                <p>
                  Edition 8 - by Mr. Venkatesh Kannan, Co- Founder,
                  Ginglebid{" "}
                </p>
                <p>on 18 August 2022 </p>
                <div className="w-full flex items-end justify-end">
                  <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/162vNQVs8DRGD0n4sKrPC2ZTz8prnQI0U?usp=sharing"
                    >
                      Gallery
                    </a>
                  </button>
                </div>
              </div> */}
            </div>
            
        </main>
    <Footer />
    </>
  )
}

export default TriumphTalk