import { React, useState, useEffect } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import "./css/Team.css";
import about1 from "./image/about1.png";
import IIC_banner from "./image/IIC_banner.png";
import establish from "./pdf/EstablismentCertificate-1.png";
import appreciation from "./pdf/IIC_Appreciation_Letter_AY_2021_22-1.png";
import rate1 from "./pdf/RatingCertificate 2018-19-1.png";
import rate2 from "./pdf/RatingCertificate_2019-20-1.png";
import rate3 from "./pdf/RatingCertificate_2020-21-1.png";
import rate4 from "./pdf/RatingCertificate_2021-22-1.png";

// import FsLightbox from "fslightbox-react";


import {
    Heading,
    Tabs, 
    TabList,
    TabPanels, 
    Tab, 
    TabPanel,
    Stack,
    Text,
    Divider,
    List,
    ListItem,
    ListIcon,
    Box,
    Image,
    Card, 
    CardHeader, 
    CardBody,
    SkeletonText,
    StackDivider,
    Button,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
}from "@chakra-ui/react";

import { AiOutlineMail, AiOutlineIdcard } from 'react-icons/ai'

import { BsDot, BsFillPersonFill } from "react-icons/bs";

const IIC = () => {

    const [comisLoading, setcomIsLoading] = useState(true);
    const [comdata, setcomData] = useState([]);
    
    
    useEffect(() => {
        // Simulate an API call or data loading process
        setTimeout(() => {
        import('./committee.json').then((comdata) => {
            setcomData(comdata.default); 
            setcomIsLoading(false); 
        })
        .catch((error) => {
            console.error('Error importing committee.json', error);
            setcomIsLoading(false);
        })
        }, 4000); 
    }, []);
      

    const [jsonData, setJsonData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       
        setTimeout(() => {
          import('./activities.json')
            .then((data) => {
              setJsonData(data.default);
              setIsLoading(false); 
            })
            .catch((error) => {
              console.error('Error importing JSON data: ', error);
              setIsLoading(false); 
            });
        }, 4000); 
      }, []);

      
  return (
    <>
    <Navigation/>
    <main className='min-w-screen'>
        <section className='abouttop'>
            <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </section>
        <section className='flex flex-col abouttop imgbackabout items-center justify-center sticky first-letter:'>
            <div className=" event3 ">
            <h3 className="animate-character shadow-sm text-4xl md:text-6xl font-ubuntu mt-6">
                {`Institution's Innovation Council (IIC)`}
            </h3>
            </div>
            <img className=" w-8/12" src={IIC_banner} alt="BackgroundImage" />
        </section>
        <section className='flex w-screen md:w-11/12 justify-left flex-row h-auto my-8 mx-4'>
            <div className='hidden md:flex flex-col  w-1/3'>
                <Accordion defaultIndex={[0]} allowToggle>
                    <AccordionItem>
                    <AccordionButton>
                        <Heading as='h1' size='md' className=" font-inter text-2xl border-b my-2 border-primaryYellow">Policies</Heading >
                        <AccordionIcon/>
                    </AccordionButton>
                <AccordionPanel >
                    <Stack className='  gap-4'>
                        <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://webstor.srmist.edu.in/web_assets/downloads/2022/national-innovation-and-startup-policy-2019.pdf')}}>NISP</Button>
                        <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://webstor.srmist.edu.in/web_assets/downloads/2022/srm-innovation-ipr-startup-policy.pdf')}}>IPR & Startup Policy</Button>
                        <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://webstor.srmist.edu.in/web_assets/downloads/2022/policy-formulation-members-nisp.pdf')}}>TamilNadu Startup and Innovation Policy</Button>
                        <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://webstor.srmist.edu.in/web_assets/downloads/2022/tn-startup-innovation-policy.pdf')}}>Policy Formulation Members</Button>                    
          
                    </Stack>
                </AccordionPanel>
                </AccordionItem>
                <AccordionItem >
                    <AccordionButton>
                        <Stack>
                            <Heading as='h1' size='md' className='font-inter  border-b my-2 border-primaryYellow'>Contact Details</Heading>
                        </Stack>
                        <AccordionIcon/>
                    </AccordionButton>
                    <AccordionPanel>
                    <Stack className='  gap-4'>
                        
                        {/* <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://webstor.srmist.edu.in/web_assets/downloads/2022/patents-research.pdf')}}>Patents</Button> */}
                         <div as='p' className='text-justify font-inter border border-primaryYellow p-1'>
                        <strong><AiOutlineIdcard/>Head Of Institute(HOI)<br/></strong>
                        Dr. C. Muthamizhchelvan<br/>
                        Vice Chancellor, SRMIST<br/>
                        vc@srmist.edu.in 
                        </div><br/>


                        <div as='p' className='text-justify font-inter border border-primaryYellow p-1'>
                        <strong><AiOutlineIdcard/>President, IIC<br/></strong>
                        Dr. Shantanu Patil<br/>
                        assocdirector.iiec@srmist.edu.in<br/>
                        +91 7030142727
                        </div><br/>


                        <div as='p' className='text-justify font-inter border border-primaryYellow p-1'>
                        <strong><AiOutlineIdcard/>Vice President, IIC<br/></strong>
                        Dr. R. Ananth Kumar<br/>
                        cio.iiec@srmist.edu.in<br/>
                        +91 80560 78340
                        </div><br/>


                        <div as='p' className='text-justify font-inter border border-primaryYellow p-1 '>
                        <strong><AiOutlineIdcard/>Convener, IIC<br/></strong>
                        Mr. Ramesh K<br/>
                        convener.iiec@srmist.edu.in<br/>
                        +91 98944 23131 
                        </div>
                    </Stack>
                    </AccordionPanel>
                </AccordionItem>
                </Accordion>
                <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://www.srmist.edu.in/research/awards-and-recognition/')}}>Awards and Recognitions</Button>
                <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://www.srmist.edu.in/research/sponsored-projects/')}}>Sponsored Projects</Button>
                <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://www.srmist.edu.in/research/highlights/')}}>Research Highlights</Button>                    
                <Button bg='yellow.400' className=' button my-1 py-2 px-12 font-ubuntu text-md border border-primaryYellow' onClick={() => {window.open('https://www.srmist.edu.in/research/research-and-development-cell/')}}>Research and Development Cell</Button>    
            </div>
            {/* Add read more button */}
            <div className='flex m-0 md:ml-12 flex-row w-screen md:w-full rounded-md'>
                <Tabs align='center' variant='enclosed' colorScheme='linkedin' isLazy >
                <TabList mb='1em' >
                    <Tab bg='yellow.400'><Heading as='h4' size='sm'>About</Heading></Tab>
                    <Tab bg='yellow.400'><Heading as='h4' size='sm'>Activities</Heading></Tab>
                    <Tab bg='yellow.400'><Heading as='h4' size='sm'>Certificates</Heading></Tab>
                    <Tab bg='yellow.400'><Heading as='h4' size='sm'>Committee</Heading></Tab>
                    <Tab bg='yellow.400'><Heading as='h4' size='sm'>Annual Reports</Heading></Tab>
                    
                </TabList>
                <TabPanels>
                    <TabPanel>
                    <section className='md:w-full my-6 text-left'>
                        <Heading as='h5' size='sm' className='border-b border-primaryYellow mx-1 font-inter'>Introduction </Heading>
                        <Text align="left" className='my-6 font-serif leading-loose'>
                        {`In the year 2018, the Ministry of Education (MoE) through MoE's Innovation Cell (MIC) launched the Institution’s Innovation Council (IIC) program in collaboration with AICTE for Higher Educational Institutions (HEIs) to systematically foster the culture of innovation and start-up ecosystem in education institutions. Primarily, IICs’ role is to engage large number of faculty, students and staff in various innovation and entrepreneurship related activities such as ideation, Problem solving, Proof of Concept development, Design Thinking, IPR, project handling and management at Pre-incubation/Incubation stage, etc., so that innovation and entrepreneurship ecosystem gets established and stabilized in HEIs. The IIC model is designed to address the existing challenges/issues in HEIs such as less numbers, occasional and unplanned Innovation & Entrepreneurship (I&E) activities organised in HEIs with low involvement of top leadership, lack of coherence and absence of synergy in resource mobilization, deployment and underutilization of creative potential of youths as major barrier for vibrant I&E ecosystem to emerge from HEIs.
`}<br/><br/>{`IIC model is unique and distinct as it integrates the functionalities of flexibility calendar activities, scoring and reward system, decentralizing operation with division of work, progress monitoring and incentive mechanisms in coordinated manner through a robust digital platform. In last two years, undoubtedly, IIC has emerged as a very sustainable and scalable model for promoting innovation within HEIs and the efforts of these IIC Institutes can be seen as impactful contribution towards making country’s innovation and start-ups ecosystem more vibrant and dynamic. The recent achievement of India’s 48th positon in Global Innovation Index (GII) ranking is a reflection of the same.
`}
                        </Text>
                        <Divider colorScheme='yellow'/>
                        <Heading as='h5' size='sm' className='border-b border-primaryYellow md:mx-1 my-8 font-inter' >Major Focus</Heading>
                        <List>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />To create a vibrant local innovation ecosystem.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Start-up supporting mechanism in HEIs.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Prepare institute for Atal Ranking of institutions on innovation achievements framework.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Establish a functional ecosystem for scouting ideas and pre-incubation of ideas.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Develop better cognitive ability among students.</ListItem>
                        </List>
                        <Divider colorScheme='yellow'/>
                        <Heading as='h5' size='sm' className='border-b border-primaryYellow md:mx-1 my-8 font-inter' >Function of IIC</Heading>
                        <List>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />To conduct various Innovation, IPR and entrepreneurship-related activities prescribed by  MIC in time bound fashion.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Identify and reward innovations and share success stories.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Organize periodic workshops/ seminars/ interactions with entrepreneurs, investors, professionals and create a mentor pool for student innovators.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Network with peers and national entrepreneurship development organizations.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Create an Institution's Innovation portal to highlight innovative projects carried out by institution's faculty and students.</ListItem>
                            <ListItem className='py-1 font-serif'><ListIcon as={BsDot} color='yellow.500' />Organize Hackathons, idea competition, mini-challenges etc. with the involvement of industries.</ListItem>
                        </List>
                        
                    </section>
                    </TabPanel>
                    <TabPanel>
                    <section className='w-full my-6  '>
                        <Heading as='h1' size="lg" className='my-6'>IIC Activities Organized for the Period - 01.09.2022 to 31.08.2023</Heading>
                    {
                        isLoading ? (
                            <Stack className='mx-6'>
                                <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                                <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                                <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                    <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                            </Stack>
                        ) : (
                            jsonData.map((activity, index) => (
                               <div className='w-full justify-center '>                                
                               <Card key={index} >
                                <CardHeader className=' mt-4  border-x border-t  border-primaryYellow rounded-t-md'>
                                  <Heading size='md' className="font-bold">{activity.name}</Heading>
                                </CardHeader>
                                <CardBody className=' shadow-md border-x border-b border-primaryYellow rounded-b-md'>
                                  <Stack divider={<StackDivider color='yellow.500' />} spacing='4'>
                                    <Box className='flex flex-row justify-evenly'>
                                        <Stack>
                                            <Text  fontSize='md'>
                                                {activity.quarter}
                                            </Text>
                                            
                                            <Text  fontSize='lg'>
                                                {activity.type}
                                            </Text>
                                            
                                        </Stack>
                                        <a href={activity.downloadlink} target="_blank" rel="noopener noreferrer">
                                            <Button bg='yellow.400' className='button'>
                                                View Report
                                            </Button>         
                                        </a>
                                    </Box>
                                    
                                  </Stack>
                                </CardBody>
                              </Card>
                              </div>
                        )))
                    }
                    </section>
                    </TabPanel>
                    <TabPanel>
                    <section className='w-full my-6 '>
                        <div className='w-fit flex flex-col justify-center items-center'>
                            <Box >
                                <Heading>Certificate of Establishment</Heading>
                                <Image  src={establish} alt='Establistment certificate' />
                                
                            </Box>
                            <Box >
                                <Heading>Letter of Appreciation</Heading>
                                <Image src={appreciation} alt='Letter of Appreciation' />
                            </Box>
                            <Box >
                                <Heading>Rating Certificate 2018-19</Heading>
                                <Image src={rate1} alt='Rating Certificate 2018-19' />
                            </Box>
                            <Box >
                                <Heading>Rating Certificate 2019-20</Heading>
                                <Image src={rate2} alt='Rating Certificate 2018-19' />
                            </Box>
                            <Box >
                                <Heading>Rating Certificate 2020-21</Heading>
                                <Image src={rate3} alt='Rating Certificate 2018-19' />
                            </Box>
                            <Box >
                                <Heading>Rating Certificate 2021-22</Heading>
                                <Image src={rate4} alt='Rating Certificate 2018-19' />
                            </Box>
                        </div>
                    </section>
                    </TabPanel>
                    <TabPanel>
                    <section className='w-11/12 my-6  '>
                    <Heading as='h1' size="lg" className='my-6'>Innovation Ambassadors</Heading>
                        {comisLoading ? (
                            <Stack className='mx-6'>
                            <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                            </Box>
                            <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                            </Box>
                            <Box padding='6' boxShadow='lg' bg='white' w='2xl'>                
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                <SkeletonText startColor='yellow.100' endColor='yellow.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                            </Box>
                        </Stack>
                        ) : (
                            <div className='flex flex-row flex-wrap gap-2'>
                                {comdata.map((item) => (
                                <div className='w-full justify-center '>                                
                                <Card key={item.index} >
                                <CardHeader className=' mt-4  border-x border-t  border-primaryYellow rounded-t-md'>
                                <Heading size='md' className="font-bold">{item.name}</Heading>
                                </CardHeader>
                                <CardBody className=' shadow-md border-x border-b border-primaryYellow rounded-b-md'>
                                <Stack divider={<StackDivider color='yellow.500' />} spacing='4'>
                                    <Box className='flex flex-col justify-evenly'>
                                        <Stack>
                                            <Text  fontSize='md'>
                                                {item.designation}
                                            </Text>
                                            
                                            <Text  fontSize='lg'>
                                                {item.post}
                                            </Text>
                                            
                                        </Stack>
                                        <div className='flex flex-row'>
                                            <a href={`mailto:${item.email}`} className='w-max text-primaryYellow'>
                                                <AiOutlineMail style={{width:'32px'}}/>     
                                            </a>
                                            <a href={item.linkedin} className='w-max text-primaryYellow'>
                                                <BsFillPersonFill style={{width:'32px'}}/>     
                                            </a>
                                        </div>
                                        
                                    </Box>
                                    
                                </Stack>
                                </CardBody>
                            </Card>
                            </div>
                            ))}
                        </div>
                    )}
                    </section>
                    </TabPanel>
                    <TabPanel >
                    <section className=' mx-32 min-h-screen overflow-auto min-w-screen flex flex-col gap-2 '>
                    <Accordion allowToggle className='buttonc'>
                        <AccordionItem>
                           <AccordionButton className="button my-6 bg-primaryYellow bg-opacity-90" _hover={{bg:'rgba(255,191,0,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
                                    IIC Annual Report 2021-22
                                    <AccordionIcon/>
                           </AccordionButton>
                           <AccordionPanel>
                           <div className='container mt-2'>
                            <div className=' reports'>
                                    <object
                                        data={require("./pdf/IIC_annual_report.pdf")}
                                        type="application/pdf"
                                        width="130%"
                                        height="400%"
                                        aria-label='IIC Annual Report 2021-22'
                                        className='w-11/12 h-11/12'
                                    ></object>
                                </div>
                            </div>
                           </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem >
                        <AccordionButton className="button my-6 bg-primaryYellow bg-opacity-90" _hover={{bg:'rgba(255,191,0,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
                                IIC Activity Annual Remote
                                <AccordionIcon/>
                            </AccordionButton>
                            <AccordionPanel>
                            <div className='container mt-2'>
                            <div className='reports'>
                                <object
                                    data={require("./pdf/IIC_Activity_annual_report.pdf")}
                                    type="application/pdf"
                                    width="130%"
                                    height="400%"
                                    aria-label='Progress Report:2019-20 PDF'
                                    
                                ></object>
                            </div>
                            </div>
                            </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
                        
                    
                    {/* 
                            
                    </Accordion> */}
                    
                    </section>
                    </TabPanel>
                </TabPanels>
                </Tabs>
            </div>
        </section>
    </main>
    <Footer />
    </>
  )
}

export default IIC