import React from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import "./css/Team.css";
import about1 from "./image/about1.png";

const EIR = () => {
  return (
    <>
    <Navigation />
    <main>
        <div className="abouttop">
            <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="event3">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            DEI - EIR
          </h3>
        </div>
    </main>
    <Footer />
    </>
  )
}

export default EIR