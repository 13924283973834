import React from "react";
import "./css/Partner.css";
import about1 from "./image/about1.png";
import pb1 from "./image/pb1.png";
import pb2 from "./image/pb2.png";
import pb3 from "./image/pb3.png";
import pb4 from "./image/pb4.png";
import pb5 from "./image/pb5.png";
import pb6 from "./image/pb6.png";
import pb7 from "./image/pb7.png";
import pb8 from "./image/pb8.png";
import pb9 from "./image/pb9.png";
import pb10 from "./image/pb10.png";
import pb11 from "./image/pb11.png";
import pb12 from "./image/pb12.png";
import pb13 from "./image/pb13.png";
import pb14 from "./image/pb14.png";

import Footer from "./Footer";
import "./css/Event.css";
import Navigation from "./Navigation";

const Patner = () => {
  return (
    <>
      <Navigation />

      <div className="contpatner">
        <div className="patop">
          <div className="abouttop">
            <img className="imgbackabout" src={about1} alt="BackgroundImage" />
          </div>
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            Partners
          </h3>
        </div>

      {/* <div className="grid grid-cols-2 md:grid-cols-5 my-20 mx-2 md:mx-30 gap-x-2 md:gap-x-2 gap-y-2"> */}
      <div className="flex flex-wrap p-2 flex-row m-0 justify-center items-center md:gap-6 lg:gap-12">
        <a href="https://www.ediindia.org/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb1}
            alt="partner-image"
          />
          </a>
          <a href="https://iedc.srmiiec.com/" target="_blank">
          <img
            className="w-full md:w-auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb2}
            alt="partner-image"
          />
          </a>
          <a href="https://jacobsinstitute.berkeley.edu/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb3}
            alt="partner-image"
          />
          </a>
          <a href="https://scet.berkeley.edu/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb4}
            alt="partner-image"
          />
          </a>
          <a href="https://www.kingston.ac.uk/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb5}
            alt="partner-image"
          />
          </a>
          {/* <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb6}
            alt="partner-image"
          /> */}
          <a href="https://www.intellectdesign.com/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb7}
            alt="partner-image"
          />
          </a>
          <a href="https://www.thechennaiangels.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb8}
            alt="partner-image"
          />
          </a>
          <a href="https://chennai.tie.org/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb9}
            alt="partner-image"
          />
          </a>
          <a href="https://mic.gov.in/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb10}
            alt="partner-image"
          />
          </a>
          <a href="https://iic.mic.gov.in/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb11}
            alt="partner-image"
          />
          </a>
          <a href="https://www.tees.ac.uk/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb12}
            alt="partner-image"
          />
          </a>
          <a href="https://msme.gov.in/" target="_blank">
          <img
            className="w-full md:auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb13}
            alt="partner-image"
          />
          </a>
          <a href="https://dst.gov.in/" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src={pb14}
            alt="partner-image"
          />
          </a>
          <a href="https://www.d-thinking.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://media.licdn.com/dms/image/C510BAQH46Ym5a6Xjow/company-logo_200_200/0/1519905852339?e=2147483647&v=beta&t=9rdmH1KnLn_iHJ_8V2WHS4lowQPr37BBLG7mdrkdF4c"
            alt="partner-image"
          />
          </a>
          <a href="https://www.startupindia.gov.in" target="_blank">
          <img
            className="w-full md:w-auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://www.startupindia.gov.in/content/dam/invest-india/newhomepage/Logo1.png"
            alt="partner-image"
          />
          </a>
          <a href="https://startuptn.in" target="_blank">
          <img
            className="w-full md:w-auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://startuptn.in/wp-content/uploads/2023/01/Startuptn-logo.png"
            alt="partner-image"
          />
          </a>
          <a href="https://www.thecampusangels.com" target="_blank">
          <img
            className="w-full md:w-auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://www.thecampusangels.com/wp-content/uploads/2023/03/MicrosoftTeams-image-3-2-150x150.jpg"
            alt="partner-image"
          />
          </a>
          <a href="https://jajalmedical.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://images.crunchbase.com/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ukbwra7ob3ljfhihkgok"
            alt="partner-image"
          />
          </a>
          <a href="https://bridgebharatcouncil.org" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://bridgebharatcouncil.org/assets/images/bridge-bharat-logo-pdf-modified-96x69.png"
            alt="partner-image"
          />
          </a>
          <a href="https://www.stpinext.in" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://www.stpinext.in/wp-content/uploads/2019/11/AIC-STPINEXT-INITIATIVES-logo-7-A-01-150x150.png"
            alt="partner-image"
          />
          </a>
          <a href="http://pccoeciil.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="http://pccoeciil.com/assets/img/logo.png"
            alt="partner-image"
          />
          </a>
          <a href="https://isba.in" target="_blank">
          <img
            className="w-full md:w-auto h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://isba.in/wp-content/uploads/2020/01/logo-cropped.png"
            alt="partner-image"
          />
          </a>
          <a href="https://www.tnthub.org" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://media.licdn.com/dms/image/D560BAQG2ZDhHj0NGxQ/company-logo_200_200/0/1689680584203?e=2147483647&v=beta&t=PiHSndDHRznONts68VMFLjetCRZSwCrMsVWZwkRK29g"
            alt="partner-image"
          />
          </a>
          <a href="https://www.rkdewan.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://www.rkdewan.com/images/logo/logo-n.png"
            alt="partner-image"
          />
          </a>
          <a href="https://vakilsearch.com" target="_blank">
          <img
            className="w-full md:w-72 h-28 md:h-52 hover:shadow-md rounded-md border border-primaryYellow p-4 md:p-10"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Vakilsearch-logo.svg/1200px-Vakilsearch-logo.svg.png?20181018115356"
            alt="partner-image"
          />
          </a>


        </div>
      </div>

      <Footer />
    </>
  );
};

export default Patner;
