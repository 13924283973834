import { React, useState } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import { 
  Card, 
  CardBody, 
  CardFooter,
  Image, 
  Stack, 
  Heading, 
  Text, 
  Divider, 
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";

import "./css/Event-child.css";


const images = [
  "https://i.postimg.cc/HLSYM1MD/ST-1-Creed.jpg",
  "https://i.postimg.cc/pr3MZ2W4/ST-1-Creed-1.jpg",
  "https://i.postimg.cc/fbWWkRJf/ST-1-Creed-2.jpg",
  "https://i.postimg.cc/G3fRrjFK/ST-Creed.jpg",
]

const ShowTime = () => {
    const handleShareClick = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              url: "https://www.srmdei.com/event/st",
            });
            console.log('Link shared successfully!');
          } else {
            
            console.log('Web Share API is not supported.');
          }
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      }

      const [toggler, setToggler] = useState(false);
      const [toggler1, setToggler1] = useState(false);
      const [toggler2, setToggler2] = useState(false);
      
    return (
        <>
        <Navigation/>
            <main>
                <section className="gradient-custom custom">
                <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
                    Show Time
                </h3>
                </section>
                <div
                    className=" py-6 flex flex-row items-center justify-center"
                    >
                    <div
                        className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
                    >
                    <div className="w-full flex flex-row justify-between">
                    <h1 className="text-3xl font-ubuntu font-bold m-3">
                        Show Time
                    </h1>
                    <button
                        style={{ background: "white" }}
                        onClick={handleShareClick}
                    >
                        <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
                    </button>
                </div>

                <div className="w-full flex flex-wrap gap-6 md:gap-12 my-5">
                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">Edition 1</h1>
                    <p>by Creed Motor Works </p>
                    <p>on 07 September 2019 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1L4-atmHEa4ra5rW5MJ_EEBd6_61v8p5M?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Edition 1</Heading>
                        <Text>
                          by Creed Motor Works 
                          on 07 September 2019
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                    <Button onClick={() => setToggler(!toggler)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler}
                        sources={images}
                      />
                    </CardFooter>
                  </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">Edition 2</h1>
                    <p>by Kreator </p>
                    <p>on 07 February 2020 </p>
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1kuPcPubP5yWXsJzBqikk81CqKXpyB5_V?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Edition 2</Heading>
                        <Text>
                          by Kreator
                          on 07 February 2020
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Popover>
                        <PopoverTrigger>
                        <Button>
                          View Gallery
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>OOPPS!!!</PopoverHeader>
                          <PopoverBody>Unfortunately There are no Images for this event!</PopoverBody>
                        </PopoverContent>
                      </Popover>
                      
                    </CardFooter>
                  </Card>

                </div>
              </div>
            </div>
            
            </main>
        <Footer/>
        </>
  )
}

export default ShowTime