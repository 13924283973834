import React from "react";
import "./css/Offer.css";
// import "./css/Offerpop.css";
import p1 from "./image/p1.png";
import p2 from "./image/p2.png";
import p3 from "./image/p3.png";
import p4 from "./image/p4.png";
import p5 from "./image/p5.png";
import p6 from "./image/p6.png";
import p7 from "./image/p7.png";
import p8 from "./image/p8.png";
import p9 from "./image/p9.png";
import p10 from "./image/p10.png";
import p11 from "./image/p11.png";
import p12 from "./image/p12.png";
import Side_image from "./image/lady_work.png";

const Offer = () => {
  
  const desktopGridStyles = {
    gridTemplateRows: '10rem',
    gridTemplateColumns:  '12rem 12rem 12rem 12rem ',
  };

  return (
    <>
      <h1 className="text-primaryYellow text-3xl md:text-6xl font-bold px-4 mt-10 mb-4 text-center md:text-left font-ubuntu">
        What We Offer
      </h1>
      <div className="px-0 justify-evenly flex md:flex-row-reverse items-center my-12 ">
        
          <div className=" md:w-1/2 mt-2 pt-2">
            <div className="md:grid lg:grid-cols-4 lg:grid-rows-3 grid-cols-2 grid-rows-6 gap-4 m-auto justify-center" style={desktopGridStyles}>
              <div className=" flex flex-col justify-around items-center rounded-md border p-2  gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p1} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Seed Funding</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p2} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Work Space</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p3} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Legal Assistance</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p4} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Design Branding</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p5} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Patent Registration</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p6} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Industry Advisors</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p7} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Cloud Credits</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p8} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Technical Mentorship</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p9} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Fabrication Facility</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p10} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Market Validation</h3>
              </div>

              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p11} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold text-clip">Co-Working Space</h3>
              </div>
              <div className="flex flex-col justify-evenly items-center rounded-md border p-2 gap-10 border-primaryYellow hover:shadow-md">
                <img className="m-auto" src={p12} alt="Seed Funding" />
                <h3 className="font-Arimo font-semibold">Alumni Connect</h3>
              </div>
            </div>
          
        </div>
          <img  className="image hidden xl:flex" src={Side_image} alt="lady_working"/>
      </div>
    </>
  );
};

export default Offer;
