import { React, useState } from 'react'
import Footer from "./Footer";
import Navigation from "./Navigation";
import { 
  Card, 
  CardBody, 
  CardFooter,
  Image, 
  Stack, 
  Heading, 
  Text, 
  Divider, 
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";
import "./css/Event-child.css";


const images = [
  "https://i.postimg.cc/Y2n1y6KH/1.jpg",
  "https://i.postimg.cc/tgjPVDPh/2.jpg",
  "https://i.postimg.cc/MKnyknqj/3.jpg",
  "https://i.postimg.cc/x1HMCBkf/4.jpg",
  "https://i.postimg.cc/Tw2Dtwt1/5.jpg",
  "https://i.postimg.cc/sDb533gH/6.jpg",
]

const images1 = [
  "https://i.postimg.cc/zvT4n5md/Hackathon-Defence-Service.jpg",
  "https://i.postimg.cc/V6x3rvhr/Hackathon-Defence-Service-1.jpg",
]



const Hackathon = () => {
    const handleShareClick = async () => {
        try {
          if (navigator.share) {
            await navigator.share({
              url: "https://www.srmdei.com/event/hk",
            });
            console.log('Link shared successfully!');
          } else {
            
            console.log('Web Share API is not supported.');
          }
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      }
      const [toggler, setToggler] = useState(false);
      const [toggler1, setToggler1] = useState(false);
      const [toggler2, setToggler2] = useState(false);

  return (
    <>
        <Navigation />
        <main>
           <section className="gradient-custom customhack">
                <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
                    Hackathons
                </h3>
            </section>
            <div
                className=" py-6 flex flex-row items-center justify-center"
            >
                <div
                    className="shadow-xl md:w-10/12 sm:w-max rounded-lg "
                >
                <div className="w-full flex flex-row justify-between">
                    <h1 className="text-3xl font-ubuntu font-bold m-3">
                    Hackathons
                  </h1>
                  <button
                        style={{ background: "white" }}
                        onClick={handleShareClick}
                    >
                        <img width="32" height="32" src="https://img.icons8.com/material-sharp/96/share.png" />
                    </button>
                </div>  

                <div className="w-full flex flex-wrap gap-8 md:gap-12 my-5">
                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">Smart India Hackathon</h1>
                    <h2>Type - Hardware & Software</h2>
                    <p>8th - 12th july 2019</p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/11rSxw5IJzWmYePKdBzQOHWh6yrh0HHIt?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Smart India Hackathon</Heading>
                        <Text>
                          Type - Hardware & Software
                          8th - 12th July 2019
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler(!toggler)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler}
                        sources={images}
                      />
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      SIH Internal Hackathon
                    </h1>
                    <h2>Type - Hardware & Software</h2>
                    <p>19 - 21st March 2021</p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1YfXjbXtcmcNTP6JO0-y41ccHKDLFjXvL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}

                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>SIH Internal Hackathon</Heading>
                        <Text>
                          Type - Hardware & Software
                          19th - 21st March 2021
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler(!toggler)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler}
                        sources={images}
                      />
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Defence Service Hackathon
                    </h1>
                    <h2>Type - Hardware & Software</h2>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/12O_FOQIkUHHHEiZRaR1PlaeSUgr4tv1R?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Defence Service Hackathon</Heading>
                        <Text>
                          Type - Hardware & Software
                          
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler1(!toggler1)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler1}
                        sources={images1}
                      />
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Hackathon Coding Sprint
                    </h1>
                    <h2>Type - Software</h2>
                    <p>22nd & 23rd April 2022</p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1YfXjbXtcmcNTP6JO0-y41ccHKDLFjXvL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Hackathon Coding Sprint</Heading>
                        <Text>
                          Type - Software
                          22nd & 23rd April 2022
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler(!toggler)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler}
                        sources={images}
                      />
                      </CardFooter>
                    </Card>

                  {/* <div className="rounded border-primaryYellow border p-3 my-2">
                    <h1 className="text-lg font-bold">
                      Defence Innovation Hackathon 2022
                    </h1>
                    <h2>Type - Hardware & Software</h2>
                    <p>20 & 21st May 2022</p>{" "}
                    <div className="w-full flex items-end justify-end">
                      <button className="bg-primaryYellow rounded-md my-2 px-3 py-2">
                        <a
                          target="_blank"
                          href="https://drive.google.com/drive/folders/1YfXjbXtcmcNTP6JO0-y41ccHKDLFjXvL?usp=sharing"
                        >
                          Gallery
                        </a>
                      </button>
                    </div>
                  </div> */}
                  <Card maxW='sm'>
                    <CardBody className='rounded-md border border-primaryYellow '>
                      <Stack mt='6' spacing='3'>
                        <Heading size='md'>Defence Innovation Hackathon 2022</Heading>
                        <Text>
                          Type - Hardware & Software
                          20 & 21st May 2022
                        </Text>
                        
                      </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter className='rounded-md border border-primaryYellow '>
                      <Button onClick={() => setToggler1(!toggler1)}>
                        View Gallery
                      </Button>
                      <FsLightbox
                        toggler={toggler1}
                        sources={images1}
                      />
                      </CardFooter>
                    </Card>

                </div>
               </div>
            </div>
        </main>
        <Footer />
    </>
  )
}

export default Hackathon