import React from "react";

// const missionList = [
//   {
//     key: 1,
//     description:
//       "To Create an ecosystem to sensitize, engage and support the innovators from ideation to product development",
//   },
//   {
//     key: 2,
//     description:
//       " To Enable the innovation community with access to best in class infrastructure, mentorship and funding",
//   },
//   {
//     key: 3,
//     description:
//       "To Protect the intellectual assets evolved out of inventive process",
//   },
// ];

const Liteabout = () => {
  return (
    <>
      <div className="w-full text-center my-10 flex flex-col md:flex-row items-center justify-evenly">
        <div className="bg-primaryYellow rounded-lg px-1 m-2 sm:h-64 lg:h-60 shadow-2xl">
          <p className="flex flex-row items-center justify-center gap-5 font-extrabold text-3xl mt-4 font-ubuntu">
            Our Mission <img className="w-8 h-8 rounded-md shadow-md border border-white border-solid" src="https://i.postimg.cc/vBX75w1v/rocket.png" alt="telescope"/>
          </p>
          
          <div className="my-6 font-medium flex-2 ">
            
          <ol class="list-disc pl-6 text-justify " start={1}>
            <li class="mb-2" key={1}> To Create an ecosystem to sensitize, engage and support the innovators from ideation to product development</li>
            <li class="mb-2" key={2}> To Enable the innovation community with access to best in class infrastructure, mentorship and funding</li>
            <li class="mb-2" key={3}> To Protect the intellectual assets evolved out of inventive process</li>
            
          </ol>
            
            {/* <ol>
              {missionList.map((item) => {
                return (
                  <li className="mx-10" key={item.key}>
                    {item.key}
                    {". "}
                    {item.description}
                  </li>
                );
              })}
            </ol> */}
          </div>
        </div>

        <div className="bg-primaryYellow rounded-lg px-1 m-2 h-60 shadow-2xl">
          <p className="flex  flex-5 flex-row items-center justify-center gap-5 font-extrabold text-3xl mt-4 font-ubuntu">Our Vision<img className="w-8 h-8 rounded-md shadow-md border border-white border-solid" src="https://i.postimg.cc/nzW9GFkp/telescope.png" alt="rocket"/></p>
          <div className="my-14 mx-10 font-medium text-justify ">
            Promote an entrepreneurial mindset, Kindle the fires of innovation
            in the campus and Contribute to building of the self-reliant nation
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <a href="./about">
          <button className="button bg-primaryYellow px-10 py-2">Learn More</button>
        </a>
      </div>
    </>
  );
};

export default Liteabout;
