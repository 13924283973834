import Home from "./js/Home";
import About from "./js/About";
import Partner from "./js/Partner";
import Team from "./js/Team";
import Event from "./js/Event";
import Cources from "./js/Cources";

import Home_ramapuram from "./js/Home_ramapuram";
import Home_ncr from "./js/Home_ncr";
import Home_vada from "./js/Home_vada";
import Home_trichy from "./js/Home_trichy";

import ShowTime from "./js/ShowTime";
import TriumphTalk from "./js/TriumphTalk";
import Bootcamp from "./js/Bootcamp";
import Workshop from "./js/Workshop";
import Webinar from "./js/Webinar";
import Hackathon from "./js/Hackathon";

import Mentors from "./js/Mentors";
import EIR from "./js/EIR";
import IIC from "./js/IIC";
// import Navigation from "./js/Navigation";

// //IEDC Components
// import Downloads from './Components/Downloads';
// import Homes from './Components/Home';
// // import Navbar from './Components/Navbar';
// import Projects1 from './Components/Projects1';
// import Projects2 from './Components/Projects2';
// // import Footer from './Components/Footer';
// import Committees from './Components/Committees';
// import Newsstories from './Components/Newsstories';
// import Activities from './Components/Activities';

// import Fablab from './Components/Fablab'
// import Login from './Components/Login'
// import Signup from './Components/Signup'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        {/* <Navigation /> */}
        <Routes>
          <Route path="/" element={<Home />} />

          {/* <Route path="/ramapuram" element={<Home_ramapuram />} />
          <Route path="/ncr" element={<Home_ncr />} />
          <Route path="/vadapalani" element={<Home_vada />} />
          <Route path="/trichy" element={<Home_trichy />} /> */}

          <Route path="/about" element={<About />} />
          <Route path="/event" element={<Event />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/team" element={<Team />} />
          <Route path="/mentors" element={<Mentors />} />
          <Route path="/cources" element={<Cources />} />
          <Route path="/event/tt" element={<TriumphTalk />} />
          <Route path="/event/st" element={<ShowTime />} />
          <Route path="/event/bc" element={<Bootcamp />} />
          <Route path="/event/ws" element={<Workshop />} />
          <Route path="/event/wb" element={<Webinar/>} />
          <Route path="/event/hk" element={<Hackathon/>} />
          <Route path="/DEI-EIR" element={<EIR/>} />
          <Route path="/IIC" element={<IIC/>} />

          {/* <Route exact path='/iedc/' element={<Homes titleChange='Home' />} />
          <Route exact path='/iedc/1yearprojects' element={<Projects1 titleChange='Projects' />} />
          <Route exact path='/iedc/2yearprojects' element={<Projects2 titleChange='Projects' />} />
          <Route exact path='/iedc/committees' element={<Committees titleChange='Committees' />} />
          <Route exact path='/iedc/newsstories' element={<Newsstories titleChange='News Stories' />} />
          <Route exact path='/iedc/downloads' element={<Downloads titleChange='Downloads' />} />
          <Route exact path='/iedc/activities' element={<Activities titleChange='Activities' />} />
          <Route exact path='/fablab/' element={<Fablab />} />
          <Route exact path='/fablab/login' element={<Login  />} />
          <Route exact path='/fablab/signup' element={<Signup />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
