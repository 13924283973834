import { IoLocationOutline, IoCallOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";

export default function Contact({frameNumber}) {

  const changeFrameNumber = {
    ktr: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.226465583124!2d80.0435915!3d12.8234525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfdd1b894c089aaa5!2sSRM%20Innovation%20and%20Incubation%20Centre!5e0!3m2!1sen!2sin!4v1665147391711!5m2!1sen!2sin',
    ramapuram: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.226465583124!2d80.0435915!3d12.8234525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfdd1b894c089aaa5!2sSRM%20Innovation%20and%20Incubation%20Centre!5e0!3m2!1sen!2sin!4v1665147391711!5m2!1sen!2sin',

    
  }

  return (
    <>
      <div className="flex flex-col justify-evenly items-center">
        <p className="font-bold text-4xl mb-3 mt-2 flex item-center justify-center font-ubuntu hover:text-primaryYellow transition-all ">
          Contact Us
        </p>
        <p className="text-center w-fit px-2 border-b-2 border-primaryYellow">
          Feel free to get in touch with us and say Hi!
        </p>

        <div className="flex flex-col md:flex-row-reverse gap-2 lg:gap-4 items-center justify-around px-4 my-8">
          <div className="w-full md:w-8/12 mt-3">
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15561.226465583124!2d80.0435915!3d12.8234525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfdd1b894c089aaa5!2sSRM%20Innovation%20and%20Incubation%20Centre!5e0!3m2!1sen!2sin!4v1665147391711!5m2!1sen!2sin'
              frameborder="0"
              width="100%"
              height="400rem"
              allowfullscreen
              title="SIIECLocation"
            ></iframe>
          </div>

          <div className="flex flex-col w-full md:w-4/12 px-3 my-4 md:my-0">
            <div className="rounded shadow-md p-6 w-full flex flex-col justify-center h-96 ">
              <p className="font-bold text-xl mb-2">Get in touch</p>

              <div className="flex flex-row items-center">
                <IoLocationOutline
                  size={30}
                  className="text-primaryYellow mx-1"
                />
                <h4 className="font-bold font-mono hover:text-primaryYellow">Location:</h4>
              </div>
              <p>
                <h5 className="py-2 px-6">
                  5th Floor, Basic Engineering Lab, SRM Institute of Science and
                  Technology, Chennai, Tamil Nadu - 603203
                </h5>
              </p>

              <div className="flex flex-row items-center mt-2">
                <MdOutlineMailOutline
                  size={30}
                  className="text-primaryYellow mx-1"
                />
                <h4 className="font-bold font-mono hover:text-primaryYellow">Email:</h4>
              </div>
              <p>
                <h5 className="py-2 px-6">gm.dei@srmist.edu.in</h5>
              </p>
              <p>
                <h5 className="py-2 px-6">admin.sidc@srmist.edu.in</h5>
              </p>
              <div className="flex flex-row items-center mt-2">
                <AiOutlinePhone
                  size={32}
                  className="text-primaryYellow mx-1"
                />
                <h4 className="font-bold font-mono hover:text-primaryYellow">Contact No.:</h4>
              </div>
              <p>
                <h5 className="py-2 px-6 font-serif">+91 80562 72947</h5>
              </p>
              <p>
                <h5 className="py-2 px-6 font-serif">+91 96778 86632</h5>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
