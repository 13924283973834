export default function Aboutcard({ props }) {
    return (
      <>
       <div className="flex flex-col my-12 md:my-10">
          <p className="w-full text-center text-4xl font-bold my-6 font-ubuntu">{props.mainhead}</p>
          <div className="flex flex-col md:flex-row w-full">
            <p className="mx-auto md:mx-28 my-auto text-base text-center font-medium md:text-left md:text-2xl w-1/2">
              {props.body}
            </p>
            
            <div className="w-full md:w-1/2 my-5 md:my-0">
              <img className="w-2/3 m-auto" src={props.image} alt="about-img" />
            </div>
          </div>
        </div>
      </>
    );
  }
  