import React from "react";
import "./css/Cources.css";
// import mybanner from "./image/mybanner.jpg";
// import srmlogo from "./image/srmlogo.png";
import abt from "./image/abt.png";
import about1 from "./image/about1.png";
import whyMinor from "./image/whyMinor.png";
import Objectives from "./image/Objectives.png";
import Footer from "./Footer";
import minorsRules from "./image/minorsRules.png";
import Eligibility from "./image/Eligibility.png";
import Overview from "./image/overview.png";
import offerings from "./image/offerings.png";

import {AiOutlineDownload} from 'react-icons/ai';
import { BsBoxArrowInUpRight } from 'react-icons/bs';

import Design_Thinking_and_Methodology_Curriculum_UP from "./docs/Design_Thinking_and_Methodology_Curriculum_UP.pdf";
import Technology_Entrepreneurship_Curriculum_UP from "./docs/Technology_Entrepreneurship_Curriculum_UP.pdf";
import Technology_Design_Foundation_Curriculum_UP from "./docs/Technology_Design_Foundation_Curriculum_UP.pdf";
import Seminar_on_Design_Innovation_Curriculum_UP from "./docs/Seminar_on_Design_Innovation_Curriculum_UP.pdf";
import Challenge_Project_on_Product_Prototyping_Curriculum_up from "./docs/Challenge_Project_on_Product_Prototyping_Curriculum_up.pdf";
import Navigation from "./Navigation";

import { Box } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import minorcert from "./docs/EANDDI.pdf";
import biodesign from "./docs/Biodesign_updated.pdf";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

export const Cources = () => {

  const onButtonClick = (doclink) => {
    // using Java Script method to get PDF file
    fetch(doclink).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'course_details.pdf';
            alink.click();
        })
    })}

  return (
      <>
      <Navigation/>
    <div>
      <div className="mainCources">
      <div className="abouttop">
          <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="flex flex-col justify-start items-center">
        <div className="">
          <h2 className="animate-character text-3xl my-8 mb-0 md:text-7xl font-ubuntu">
            Minor Courses
          </h2>
        </div>
          <p className="mb-4 p-0 ">{`(Offered by SRM Design and Innovation Centre)`}</p>
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-center items-center w-full">
      <Accordion className="mt-4 mb-24" allowToggle>
      <AccordionItem >
        <h2 className="font-inter text-2xl font-bold  h-12 hover:shadow-lg w-full  text-center">
          <AccordionButton className="button1 bg-primaryYellow bg-opacity-90" _hover={{bg:'rgba(255,191,0,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
            <Box  as="span" flex='1' textAlign='center' >
              Minor in Entrepreneurship and Design Innovation
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        <div className="minorstruct flex flex-col justify-between items-center">
            <h1 className=" text-2xl hover:shadow-lg w-fit">MINOR DEGREE STRUCTURE</h1>
            <h2>
              Offered by SRM Innovation and Design Centre (SIDC) 
            </h2>
          </div>
          <div className="whyMinor">
            <img src={Overview} alt="overview" />
            <p>The course offers SRMIST undergraduate students the opportunity to learn about technology innovation and entrepreneurship inspired by the Berkeley Method of Entrepreneurship.</p>
          </div>
          <div className="objOfProgram">
            <img src={Objectives} alt="" />
            <p>The mindsets and skills introduced in Minor classes prepare students to become innovative leaders in their industry, jobs or ventures regardless of the field.<br/>
            </p>
            <ul className="p-4 gap-2">
              <li>
              To impart to students basic fundamental and applied knowledge in the SIDC discipline.
              </li>
              <li>
                To convey an understanding of the continuously increasing
                contributions of SIDC to society.
              </li>
              <li>To obtain a working knowledge of core SIDC principles.</li>
              <li>
                To understand the current state-of-the-art within the SIDC
                discipline, and to bridge between their major discipline of study
                and that of SIDC.
              </li>
            </ul>
          </div>
          <div className="whyMinor">
            <img src={offerings} alt="" />
            <ul className="gap-4 p-4">
              <li>Learn from experts</li>
              <li>Address real-world problems </li>
              <li>Mentor Pool Access</li>
              <li>Seed Funding</li>
              <li>Build networks</li>
            </ul>

          </div>
          <div className="minor-in-design flex items-center justify-center">
            <h2 className="border-b border-primaryYellow w-fit text-2xl font-semibold">Course Curriculum</h2>
          </div>
          <div className="main-table-cources">
            <table id="table-cources">
              <tr>
                <th>Category</th>
                <th>course Code</th>
                <th>Course Title</th>
                <th>L</th>
                <th>T</th>
                <th>P</th>
                <th>C</th>
                
                <th>Credits to be earned</th>
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC001J</td>
                <td>
                  <a className="link-nostyle" href={Design_Thinking_and_Methodology_Curriculum_UP} target="_blank">
                    Design Thinking and Methodology
                  </a>
                </td>
                <td>2</td>
                <td>0</td>
                <td>4</td>
                <td>4</td>
                
                <td>4</td>
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC002J</td>
                <td>
                  <a className="link-nostyle" href={Technology_Entrepreneurship_Curriculum_UP} target="_blank">
                    Technology Entrepreneurship
                  </a>
                </td>
                <td>2</td>
                <td>0</td>
                <td>4</td>
                <td>4</td>
                
                <td>4</td>
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC003J</td>
                <td>
                  <a className="link-nostyle" href={Technology_Design_Foundation_Curriculum_UP} target="_blank">
                    Technology Design Foundation
                  </a>
                </td>
                <td>2</td>
                <td>0</td>
                <td>4</td>
                <td>4</td>
                
                <td>4</td>
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC004T</td>
                <td>
                  <a className="link-nostyle" href={Seminar_on_Design_Innovation_Curriculum_UP} target="_blank">
                    Seminar on Design Innovation
                  </a>
                </td>
                <td>1</td>
                <td>0</td>
                <td>0</td>
                <td>1</td>
                
                <td>1</td>
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC005L</td>
                <td>
                  <a className="link-nostyle" href={Challenge_Project_on_Product_Prototyping_Curriculum_up} target="_blank">
                    Challenge Project on Product Prototyping
                  </a>
                </td>
                <td>0</td>
                <td>0</td>
                <td>6</td>
                <td>6</td>
                
                <td>6</td>
              </tr>
              <tr>
                <td colSpan={7}>Total Credits</td>
                <td>19</td>
                
              </tr>
            </table>
          </div>
          {/* <div className="aboutCource">
            <img src={abt} alt="" />
            <p>
              SRM Innovation and Design Centre (SIDC)transforms our day-to-day
              lives through a multitude of innovative technologies and products.
              The SIDC minor is intended to expose students from other disciplines
              to the unlimited opportunities for innovation in this exciting
              field, and to the methodologies and tools used by SIDC engineers for
              the exploration and design of new technologies and products. The
              program is expected to accommodate students of diverse backgrounds.
            </p>
            <ul>
              <li>
                An{" "}
                <u>
                  <strong>academic major</strong>
                </u>{" "}
                is the academic discipline to which an undergraduate student
                formally commits. A student who successfully completes all courses
                required for the major qualifies for an undergraduate degree
              </li>
              <li>
                <u>
                  <strong>Academic minor</strong>
                </u>{" "}
                is an academic discipline outside of the student's academic major
                in which he or she takes a small number of classes.
              </li>
              <li>
                An academic major or major field refers to a student's primary
                focus within their degree program while a minor or minor field
                refers to his or her secondary focus.
              </li>
              <li>
                <u>
                  <strong>Minors are optional.</strong>
                </u>{" "}
                You may complete multiple minors or none at all.
              </li>
              <li>
                Some students will prepare for their intended career with their
                major, while pursuing personal interests with a minor, for
                example, majoring in SIDC while minoring in a foreign language or
                performing arts or Economics or Management Studies. Other students
                may pursue a minor to provide specific specialization and thus
                make themselves more attractive to employers
              </li>
            </ul>
          </div> */}
        
          <div className="flex flex-row justify-center items-center gap-12">
            <button className="button p-2 flex flex-row w-10/12  gap-6 items-center justify-center font-mono hover:bg-primaryYellow hover:bg-opacity-60 transition-all ">
              <Link to={minorcert}          
                download="Minor Cert - Entrepreneurship and Design Innovation"
                target="_blank"
                rel="noreferrer">
                  Download Brochure
              </Link>
              <AiOutlineDownload/>
            </button>
            <button onClick={()=>{window.open("https://www.srmist.edu.in/program/minor-degree-program-in-entrepreneurship-design-innovation/")}} className="button p-2 flex flex-row w-10/12  gap-6 items-center justify-center font-mono hover:bg-primaryYellow hover:bg-opacity-60 transition-all ">
              Click Here to know more
              <BsBoxArrowInUpRight />
            </button>
          </div>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2 className="text-2xl font-inter hover:shadow-lg font-bold w-full  text-center ">
          <AccordionButton className="button1 bg-primaryYellow bg-opacity-90" _hover={{bg:'rgba(255,191,0,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
            <Box as="span" flex='1' textAlign='center'>
                Minor in BioDesign and Innovation
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <div className="minorstruct flex flex-col justify-between items-center">
            <h1 className=" text-2xl hover:shadow-lg w-fit">MINOR DEGREE STRUCTURE</h1>
            <h2>
              Offered by SRM Innovation and Design Centre (SIDC) 
            </h2>
          </div>

          
          <div className="whyMinor">
            <img src={Overview}  alt="Overview" />
            <p className="p-4">            
              It is a project-based, fast-paced course for undergraduate and Postgraduate students with an interest in entrepreneurship and a desire to improve the global healthcare sector through technological innovation.
            </p> 
          </div>

          <div className="objOfProgram">
            <img src={Objectives} alt="" />
            <p>This Course will articulate alternative and new innovative design propositions for the emerging bio-circular economy. The course will introduce students from multidisciplinary backgrounds to<br/>
            </p>
            <ul className="p-4 gap-2">
              <li>
                Whole system thinking
              </li>
              <li>
                Biological systems
              </li>
              <li>Digital and bio-fabrication techniques.</li>            
            </ul>
          </div>

          <div className="whyMinor">
            <img src={offerings} alt="" />
            <ul className="gap-4 p-4">
              <li>Learn from experts</li>
              <li>Address real-world problems </li>
              <li>Mentor Pool Access</li>
              <li>Seed Funding</li>
              <li>Build networks</li>
            </ul>

          </div>

          <div className="minor-in-design flex items-center justify-center">
            <h2 className="border-b border-primaryYellow w-fit text-2xl font-semibold">Course Curriculum</h2>
          </div>
          <div className="main-table-cources">
            <table id="table-cources">
              <tr>
                <th>Category</th>
                <th>course Code</th>
                <th>Course Title</th>
                <th>L</th>
                <th>T</th>
                <th>P</th>
                <th>C</th>
                
                
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC006L</td>
                <td>
                  
                    Identification Phase
                  
                </td>
                <td>0</td>
                <td>6</td>
                <td>0</td>
                <td>6</td>
              
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC007L</td>
                <td>
                  
                    Inventing Phase
                  
                </td>
                <td>0</td>
                <td>6</td>
                <td>0</td>
                <td>6</td>
                
              </tr>
              <tr>
                <td>Core</td>
                <td>18DCC008L</td>
                <td>
                  
                    Implementation Phase
                  
                </td>
                <td>0</td>
                <td>6</td>
                <td>0</td>
                <td>6</td>
                
              </tr>
              
              
              <tr>
                <td colSpan={3}>Total Credits</td>
                <td colSpan={4}>18</td>
                
              </tr>
            </table>
          </div>
          
          <div className="flex flex-row justify-center items-center gap-12">
            <button  className="button p-2 flex flex-row w-10/12  gap-6 items-center justify-center font-mono hover:bg-primaryYellow hover:bg-opacity-60 transition-all ">
              <Link to={biodesign}          
                download="Biodesign Brochure updated_spread"
                target="_blank"
                rel="noreferrer">             
                Download Brochure
              </Link>
              <AiOutlineDownload/>
            </button>
            <button onClick={()=>{window.open("https://www.srmist.edu.in/program/minor-degree-program-in-bio-design-innovation/")}} className="button p-2 flex flex-row w-10/12  gap-6 items-center justify-center font-mono hover:bg-primaryYellow hover:bg-opacity-60 transition-all ">
              Click Here to know more
              <BsBoxArrowInUpRight />
            </button>
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </div>
  <div className="whyMinor">
          <img src={whyMinor} alt="" />
          <ul className="ml-6 md:ml-12 list-disc">
            <li>Adding depth to your studies</li>
            <li>Adding breadth to your studies</li>
            <li>An area of passion</li>
            <li>An alternative to double majoring</li>
          </ul>
        </div>

        
        <div className="minRule">
          <img src={minorsRules} alt="" />
          <ol className="ml-6 md:ml-12 list-disc ">
            <li>
              Pursuing a major/minor program is a highly individual decision,
              and should be based on a student's educational and career goals.
            </li>
            <li>
              Minors must be completed simultaneously with a major degree
              program. You cannot earn a minor after you have already earned
              your bachelor’s degree(s).
            </li>
            <li>
              You need at least one active major in order to pursue a minor.
            </li>
            <li>
              Also be aware that since the minor must be completed with a major,
              any outstanding minor requirements will prevent the awarding of
              the degree for your major. If you ultimately decide to graduate
              without the minor, the minor must be removed from your records
              before your degree can be processed.
            </li>
            <li>
              The Certification for Minor will NOT be issued until the Major
              degree is successfully completed, even though all requirements for
              the Minor have been satisfied.
            </li>
            <li>
              All requirements for the Minor must be completed within a maximum
              of ONE semester of the completion of the Major degree but NOT
              LATER THAN the maximum duration of study of the Major degree
              permitted by UGC i.e., not more than N+2 years where 'N' is the
              normal duration of study as per regulations.
            </li>
            <li>
              If a student drops from a Minor or is NOT able to fulfil all the
              requirements for the certification of Minor, within the maximum
              period of study permitted by UGC, the student will NOT be issued
              the Certification. Nevertheless, the transcripts for the completed
              courses will be issued.
            </li>
            <li>Register for a Minor any time after 3rd Semester of B.Tech</li>
            <li>
              The number of seats for minor in SIDC is limited and subject to
              availability and academic performance.
            </li>
            <li>
              The Certification for Minor will NOT be issued until the Major
              degree is successfully completed, even though all requirements for
              the Minor have been satisfied.
            </li>
            <li>
              The offering Department scrutinises the credentials of registered
              students and announces the list of selected students who fulfil
              the minimum eligibility criteria for admission into a Minor
              Certificate programme.
            </li>
            <li>
              The selected students enrol into the Minor Certificate programme
              by paying a one-time programme fee of Rs. 75,000/- that includes
              the tuition fee, examination fee and fee for transcript and
              certificate.
            </li>
            <li>
              The student is permitted to register for a maximum of 2 courses
              per semester, over and above the maximum credits permitted (26
              credits) for a Major degree of study.
            </li>
            <li>
              A minor requires 18-20 credits to be acquired from the courses
              offered by the Dept. of SIDC
            </li>
          </ol>
        </div>
        <div className="eligc">
          <img src={Eligibility} alt="" />
          <p className="ml-6 md:ml-12">
            <strong>
              A student will be permitted to pursue a Minor, if and only if, he
              / she fulfils the following criteria:
            </strong>
          </p>
          <ol className="ml-6 md:ml-12 list-decimal" >
            <li>
              Must have secured a minimum of 7.0 CGPA* at the time of admission
              to a Minor
            </li>
            <li>There must NOT be any standing arrears / Backlogs.</li>
            <li>
              Must be in the active rolls of the department without any break of
              study or disciplinary action pending against the student
            </li>
            <li>Must have NO outstanding fee dues</li>
          </ol>
          <p className="ml-6 md:ml-12">
            <strong>
              * CGPA cut off is subject to change based on the demand and
              competition.
            </strong>
          </p>
        </div>

     <Footer/>
    </>
  );
};

export default Cources;
