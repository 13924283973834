import React, { useState } from "react";
// import { GrClose } from "react-icons/gr";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "./css/Event.css";
import about1 from "./image/about1.png";
// import bootcamp from "./image/bootcamp.png";
import triumphtalks from "./image/triumphtalks.jpg";
import cred from "./image/bootcamp.png";
import Footer from "./Footer";
import Navigation from "./Navigation";
import workshop from "./image/workshop.png";
import showtime from "./image/cred.png";
import hackathon from "./image/hackathon.JPG";
import webinar from "./image/w3.jpg";
import Marquee from "react-fast-marquee";
import bootcamp from "./image/BC-2.png"

import sih from './image/SIH.jpg';
import design from "./image/design_bootcamp.jpg";
import entre_date from "./image/entre_dating.jpg";
import { 
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,  
  Image, 
  Stack, 
  Heading, 
  Text, 
  Divider, 
  ButtonGroup, 
  Button} from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai';
const bootcampbanner = "https://srm.techfinite.com/files/WhatsApp%20Image%202024-02-27%20at%2012.35.35%20PM.jpeg"


const Event = () => {
 
  return (
    <>
      <Navigation />

      <div>
        <div className="abouttop">
          <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="event3 flex justify-center items-center">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            Events
          </h3>
        </div>
        <section className=" md:m-0 flex justify-center items-center">
          <div className="justify-center md:flex flex-col justify-self-center items-center md:w-3/4 md:p-6 md:m-6 rounded-md border border-primaryYellow hover:shadow-md">
            <h3 className="animate-character text-2xl md:text-4xl font-ubuntu mt-4 mb-4">
              Upcoming Events
            </h3>
            <Marquee style={{ width:"60vw", marginBottom: "0.5rem", zIndex: "0", overflow: "hidden" }} speed={0} gradientWidth={20} pauseOnHover>
              {[
                // {image: entre_date, url: "https://forms.gle/6EhRqB7kHjfNve5N7", cardHeading: "Entreprenuer Dating", cardtext: "Swipe right, Super-Like, and walk straight to connect with like-minded entrepreneurs/team members/ co-founders! Or if you recently had that brain-spark💡and want to pursue entrepreneurship, here is a great opportunity. ", lDate: "1 September 2023"},
                // {image: bootcampbanner, url: "https://srm.techfinite.com/event-registration-form/new", cardHeading: "Entrepreneurship Bootcamp", cardtext: "Dear Founders, the Directorate of Entrepreneurship and Innovation is organising the DESIGN BOOTCAMP. The Boot camp's primary goal is to provide participants with comprehensive training in design thinking, user experience (UX) design, and user interface (UI) design. By joining this rigorous program, we can more effectively align our design practices with market trends and create more user-centric products.", lDate: "23rd April to 27th April 2024"},
                {image: bootcamp, url: "https://srmdei.accubate.app/ext/form/2696/1/apply", cardHeading: "Entrepreneurship Bootcamp", cardtext: "Dear Founders, the Directorate of Entrepreneurship and Innovation is organising the DESIGN BOOTCAMP. The Boot camp's primary goal is to provide participants with comprehensive training in design thinking, user experience (UX) design, and user interface (UI) design. By joining this rigorous program, we can more effectively align our design practices with market trends and create more user-centric products.", lDate: "22nd - 26th Oct, 2024"},
                // {image: sih, url: "https://tinyurl.com/SRMDEI-SIH2023", cardHeading: "Smart India Hackathon", cardtext: "Dear SRMites, SIH is back with the 6th edition. SIH 2023 brings the next generation evolution by inclusion of new methodology to inculcate the culture of startup and innovation ecosystem. Smart India Hackathon is a nationwide initiative to provide students with a platform to solve some of the pressing problems we face daily, thus inculcating a culture of product innovation and a problem-solving mindset.", lDate: "Last Date to Submit: 8th September 2023"},
                // {image: "https://i.postimg.cc/4dKr6z29/evangelise.jpg", url : "https://forms.office.com/r/JiwJvj2TY4", cardHeading: "EVangelise '23 roadshow", cardtext :"Join us for the EVangelise '23 roadshow, an exciting event organized by iCreate in collaboration with SRM Institute of Science & Technology, Directorate of Entrepreneurship and Innovation(DEI) as part of the Electric Vehicle Innovation Challenge. This roadshow aims to connect with all electric vehicle enthusiasts and provide them with a unique opportunity to turn their EV dreams into reality. Don't miss out on this incredible chance!", lDate: "Last Date to Apply: 8th September 2023"},
              ].map((item) => {
                return (                  
                  <Card maxW='md' h='3xl' className="w-min md:w-max md:m-8">
                  <CardBody>
                    <Image
                      src={item.image}
                      alt={item.url}
                      objectFit="contain"
                      // borderRadius='lg' 
                    />
                    <Stack mt='8' spacing='2'>
                      <Heading size='md'>{item.cardHeading}</Heading>
                      <Text className=" font-ubuntu text-justify">
                        {item.lDate}
                      </Text>
                      
                    </Stack>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <ButtonGroup spacing='2'>
                      <Button onClick={()=>{window.open(item.url)}} variant='solid' colorScheme='yellow' className="">
                        Register
                      </Button>
                      {/* <Popover>
                        <PopoverTrigger>
                        <Button>
                          <AiOutlineInfoCircle/>
                        </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>{item.cardHeading}</PopoverHeader>
                          <PopoverBody>{item.cardtext}</PopoverBody>
                        </PopoverContent>
                      </Popover> */}
                      
                    </ButtonGroup>
                  </CardFooter>
                </Card>
                );
              })}

            </Marquee>
          </div>
        </section>
        <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mr-1 ml-1 items-center justify-center mx-auto p-3 gap-6 md:gap-8 lg:gap-12">
          
          <Card  size='sm' maxH='sm' maxW='sm' className="relative shadow-md">
            <CardBody>
              <img
                src={cred}
                className="w-8/12 rounded-md"
              />
                <Heading size='md' mt="2">Boot Camp</Heading>
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/bc">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>
          
          <Card size='sm' maxH='sm' maxW='sm' className="shadow-md">
            <CardBody>
              <Image
                src={triumphtalks}
                borderRadius='lg'
                w='md'
              />
                <Heading size='md' mt="6">Triumph Talk</Heading>
              {/* <Stack mt='6' spacing='3'>
                <Text>
                Triumph Talks, one of our flagship events, is a series of
                    talks consisting of one on one conversations of eminent
                    global personalities with our student representative in form
                    of a fireside chat followed by questions posed by the
                    audience of our community. It helps develop the thinking and
                    mindset required to excel in various domains and
                    specializations while keeping entrepreneurship in mind.
                </Text>
                
              </Stack> */}
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/tt">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>

          
          

          {/* <div className="flex flex-col md:flex-row w-full md:w-3/4 p-4 m-4 rounded-md border border-primaryYellow hover:shadow-md">
            <div className="w-full text-center md:text-left">
              {" "}
              <div className="flex flex-row">
                <img className="w-1/4 rounded-lg" src={cred} alt="event-img" />
                <div className="flex flex-col w-full">
                  <h3 className="text-3xl font-ubuntu font-bold m-3">
                    Boot Camp
                  </h3>
                  <p className="px-4 mt-4">
                    The Bootcamp is centred around the Berkeley Method of
                    Entrepreneurship, which helps students understand general
                    concepts through practice, observation, and critical
                    thinking. Every entrepreneur's path is different and
                    students come to us at many different stages in that path.
                    Our goal is to help students discover the next milestone on
                    their journey to becoming successful entrepreneurs.
                  </p>
                </div>
              </div>
              <div className="w-full flex items-end justify-end">
              <Link to="/event/bc">
                <button
                  
                  className="bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </div>
            </div>
          </div> */}
          

          {/* <div className="flex flex-col md:flex-row w-full md:w-3/4 p-4 m-4 rounded-md border border-primaryYellow hover:shadow-md">
            <div className="w-full text-center md:text-left">
              {" "}
              <div className="flex flex-row">
                <img
                  className="w-1/4 rounded-lg"
                  src={workshop}
                  alt="event-img"
                />
                <div className="flex flex-col w-full">
                  <h3 className="text-3xl font-ubuntu font-bold m-3">
                    Workshop
                  </h3>
                  <p className="px-4 mt-4">
                    Work Shop is a flagship event by SIIEC
                  </p>
                </div>
              </div>
              <div className="w-full flex items-end justify-end">
                
              <Link to="/event/ws">
                <button
                  
                  className="bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </div>
            </div>
          </div> */}
          <Card  size='sm' maxH='sm' maxW='sm' className="shadow-md">
            <CardBody>
              <Image
                src={workshop}
                w='sm'
                borderRadius='lg'
              />
                <Heading size='md' mt="2">Workshop</Heading>
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/ws">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>

          {/* <div className="flex flex-col md:flex-row w-full md:w-3/4 p-4 m-4 rounded-md border border-primaryYellow hover:shadow-md">
            <div className="w-full text-center md:text-left">
              {" "}
              <div className="flex flex-row">
                <img
                  className="w-1/4 rounded-lg"
                  src={webinar}
                  alt="event-img"
                />
                <div className="flex flex-col w-full">
                  <h3 className="text-3xl font-ubuntu font-bold m-3">
                    Webinar and Seminar
                  </h3>
                  <p className="px-4 mt-4">
                    Webinar and Seminar is a flagship event by SIIEC
                  </p>
                </div>
              </div>
              <div className="w-full flex items-end justify-end">
              <Link to="/event/wb">
                <button
                  
                  className="bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </div>
            </div>
          </div> */}
          

          {/* <div className="flex flex-col md:flex-row w-full md:w-3/4 p-4 m-4 rounded-md border border-primaryYellow hover:shadow-md">
            <div className="w-full text-center md:text-left">
              {" "}
              <div className="flex flex-row">
                <img
                  className="w-1/4 rounded-lg"
                  src={hackathon}
                  alt="event-img"
                />
                <div className="flex flex-col w-full">
                  <h3 className="text-3xl font-ubuntu font-bold m-3">
                    Hackathons
                  </h3>
                  <p className="px-4 mt-4">
                    Hackathons is a flagship event by SIIEC
                  </p>
                </div>
              </div>
              <div className="w-full flex items-end justify-end">
              <Link to="/event/hk">
                <button
                  
                  className="bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </div>
            </div>
          </div> */}
          <Card  size='sm' maxH='sm' maxW='sm' className="shadow-md">
            <CardBody>
              <Image
                src={webinar}
                w='sm'
                borderRadius='lg'
              />
                <Heading size='md' mt="2">Hackathons</Heading>
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/hk">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>

          <Card  size='sm' maxH='sm' maxW='sm' className="shadow-md">
            <CardBody>
              <Image
                src={hackathon}
                w='sm'
                borderRadius='lg'
              />
                <Heading size='md' mt="2">Webinar and Seminar</Heading>
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/wb">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>

          <Card  size='sm' maxH='sm' maxW='sm' className="shadow-md">
            <CardBody>
              <Image
                src={showtime}
                w='md'
                borderRadius='lg'
              />
                <Heading size='md' mt="6">Show Time</Heading>
              {/* <Stack mt='6' spacing='3'>
                {/* <Text>
                Show Time is a flagship event by SIIEC
                </Text> 
                
              </Stack> */}
            </CardBody>
            <Divider />
            <CardFooter>
              <ButtonGroup spacing='2'>
              <Link to="/event/st">
                <button
                  className="button bg-primaryYellow rounded-md my-2 px-3 py-2"
                >
                  View events
                </button></Link>
              </ButtonGroup>
            </CardFooter>
          </Card>

          
        </section>

        <div className="eventjoin flex flex-col items-center justify-center py-10 px-3 mt-10">
          <p className="font-bold font-ubuntu my-3 text-2xl">Join us!</p>
          <p className="my-2 text-lg text-center">
            Anyone can predict the future... a visionary shapes it!
          </p>
          <a
            target={"_blank"}
            rel="noopener norefferer"
            className="py-1 px-2"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdNnwHXdTDZa1JMiC7RfOx6rp0JHN_fRAtueu0be0mTxlM0Qw/viewform"
          >
            <button className="button p-4">Apply Now!</button>
          </a>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Event;
