import director from "./image/pp/director.jpg";
import shantanu from "./image/pp/shantanu.jpg";
import Ananth_Kmar from "./image/pp/Ananth_Kmar.jpg";
import mukesh12 from "./image/pp/mukesh12.jpeg";

import Vijay from "./image/team/VijayRatanLinga.jpeg";
import asho from "./image/pp/asho.jpg";
import vijay123 from "./image/pp/vijay123.jpeg";
import mahendran123 from "./image/pp/mahendran123.jpeg";
import Prakash from "./image/pp/Prakash.JPG";
import seema from "./image/team/Seema.jpeg";

import aniket from "./image/aniket.jpg"

import kiru from "./image/pp/kiru.jpeg";
import siva from "./image/pp/siva.jpeg";
import jenifer from "./image/team/Jenifer.jpg"

import kura from "./image/pp/kura.jpeg";
import mani from "./image/pp/santosh.png";

const users = [
  {
    name: "Dr. C. Muthamizhchelvan",
    designation: "Vice Chancellor, SRMIST",
    email: "vc@srmist.edu.in",
    linkedin:
      "https://www.linkedin.com/in/muthamizhchelvan-chellamuthu-10111a171/",
    image: director,
  },
  {
    name: "Dr. S. Ponnusamy",
    designation: "Registar, SRMIST",
    email: "registrar@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/ponnusamy-s-bb22aa3a/",
    image: "https://www.srmist.edu.in/wp-content/uploads/2022/09/Registrar_SRMIST-e1663237624653.jpeg"
  },
  {
    name: "Dr. Shantanu Patil",
    designation: "Director, DEI",
    email: "assocdirector.iiec@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/shantanu-patil-2355122/",
    image: shantanu,
  },
  {
    name: "Dr. R. Ananth Kumar ",
    designation: "Associate Director, DEI",
    email: "cio.iiec@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/ananth-kumar-india/",
    image: Ananth_Kmar,
  },
  {
    name: "Hari. S",
    designation: "CEO - Bootstrappers' Research Council (Startup Incubator)",
    email: "#",
    linkedin: "www.linkedin.com/in/hari-s-984b1915/",
    image: "https://media.licdn.com/dms/image/D5603AQGbfEPCAsIjlQ/profile-displayphoto-shrink_800_800/0/1706232156522?e=1713398400&v=beta&t=cmIxGKlIwjlKTxX-bE4R8kjGc97GRX3QfkhSqt75j8c",
  },
  {
    name: "Dr. Mukesh Krishnan M.B",
    designation: "Academic Chair",
    email: "mukeshkm@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/mukesh-krishnan-m-b-40428b119",
    image: mukesh12,
  },
  {
    name: "Mr. Vijay Rathan Linga",
    designation: "Techno-Legal Advisor",
    email: "arockiaj1@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/j-vijay-rathan-lingaa-80697723/",
    image: Vijay,
  },
 
  {
    name: "Mr. Ramesh K",
    designation: "Convener, IIC",
    email: "convener.iiec@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/ramesh-krishnamoorthy-7a2b2726/",
    image: "https://media.licdn.com/dms/image/C4D03AQG8x2hTvNapog/profile-displayphoto-shrink_800_800/0/1656475157821?e=2147483647&v=beta&t=N0rvfF4sAsdTFTQwl5YdNB0dpKj8zk33_jHHdcbawSA",
  },
  
  {
    name: "Mr. Prakash S",
    designation: "Associate - Incubation Programme",
    email: "prakashs@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/prakash-s-523b2a10a/",
    image: Prakash,
  },
  {
    name: "Mr. Ashok Kumar E S",
    designation: "Associate - Projects",
    email: "admin.siic@srmist.edu.in",
    image: asho,
  },
  {
    name: "Ms. Jenifer ",
    designation: "CIAP Coordinator",
    email: "admin.ciap@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/jenifer-j-81b27a260/",
    image: jenifer,
  },
  {
    name: "Ms. Abinaya R",
    designation: "Assistant Ecosystem Development Manager",
    email: "secy.siic@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/abinaya-rajendran-8a290b255/",
    image: "https://i.postimg.cc/y6ccJ0z4/abhinaya.jpg",
  },
  {
    name: "Mr. Vijay Kumar",
    designation: "Accounts Executive",
    email: "accounts.siic@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/vijay-d-361ba737",
    image: vijay123,
  },
  {
    name: "Mr. Mahendran Mani",
    designation: "Senior Graphic Designer",
    email: "design.siic@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/vijay-d-361ba737",
    image: mahendran123,
  },
  {
    name: "Mr. Kirubakaran D",
    designation: "Engineering Associate, FABLAB",
    email: "kirubakd@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/kirubakaran-d-097a60223/",
    image: kiru,
  },
  {
    name: "Mr. Santhosh Kumar",
    designation: "Engineering Associate, FABLAB",
    email: "santhosr2@srmist.edu.in",
    linkedin: "https://www.linkedin.com/in/santhosh-kumar-42989a13b",
    image: mani,
  },
  {
    name: "Mr. Siva Sankar",
    designation: "Engineering Associate, FABLAB",
    email: "sivasans4@srmist.edu.in ",
    linkedin:"https://www.linkedin.com/in/sivasankar-subramanian-1b726b225",
    image: siva,
  },
  {
    name: "Mr. Kuralarasan",
    designation: "Engineering Associate, FABLAB",
    email: "kuralarm1@srmist.edu.in",
    linkedin:"https://www.linkedin.com/in/kural-arasan-ba6453225",
    image: kura,
  },
  {
    name: "Mr. Naveen",
    designation: "Office Assistant",
    email: "",
    image: "https://th.bing.com/th/id/OIP.l6nPLCiWuJkhte2Ru60fdQAAAA?pid=ImgDet&rs=1",
  },
];

export default users;
