import { React, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./css/Navigation.css";

import { AiOutlineDown } from "react-icons/ai"
import { RxHamburgerMenu } from "react-icons/rx";

import {
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
} from '@chakra-ui/react'



const Navigation = () => {
  function fablabRedirect() {
    setTimeout(() => {
      window.location.href = "https://fablab.srmdei.com";
    }, []);
  }
  function iedcRedirect() {
    setTimeout(() => {
      window.location.href = "https://iedc.srmdei.com";
    }, []);
  }
  function ciapRedirect() {
    setTimeout(() => {
      window.location.href = "https://ciap.srmdei.com";
    }, []);
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  const [ismenuOpen, setIsmenuOpen] = useState(false);

  const handleHover = () => {
    setIsmenuOpen(true);
  };

  const handleLeave = () => {
    setIsmenuOpen(false);
  };


  return (
    <>
      <div className="contnavigation">
        <div class="flex flex-row-reverse sm:flex-row justify-center py-2 ">
          <ul className="hidden md:block m-0 snip1189 ">
            <li className="inline-block justify-center items-center">
              <Box position="relative" className="w-max justify-between">
              <NavLink onMouseEnter={handleHover} className="navicon py-2 font-ubuntu" to="/">
               <p className="inline-flex flex-row justify-center items-center">Home<AiOutlineDown/></p>
              </NavLink>
              {ismenuOpen && (
                <Menu
                  onClose={handleLeave}
                  isLazy
                  isOpen={ismenuOpen}
                  closeOnSelect={false}
                  
                  size="sm"
                >
                <MenuButton as="div" className=" "  >
                </MenuButton>
                <MenuList>
                    
                  <NavLink className="font-ubuntu" to="/about">
                    <MenuItem>
                        About
                    </MenuItem>
                  </NavLink>
                </MenuList>
                </Menu>
              )}
              </Box>
            </li>
            
            <li className=" inline-block">
            <Menu>
            <MenuButton className="navicon " >
              <p className="flex flex-row navicon p-2 font-ubuntu justify-center items-center gap-2">Facilities<AiOutlineDown/> </p>
            </MenuButton>
            <MenuList>
              <NavLink className=" font-ubuntu" to="/" onClick={fablabRedirect}>
              <MenuItem>
                FABLAB
              </MenuItem>
              </NavLink>

              <NavLink className=" font-ubuntu" to="/" onClick={ciapRedirect}>
              <MenuItem>
                CIAP
              </MenuItem>
              </NavLink>
              
              <MenuItem isDisabled>
                SIDC
              </MenuItem>
              
              <MenuItem isDisabled>
                DEI
              </MenuItem>
              
              
            </MenuList>
            </Menu>
            </li>

            <li className=" inline-block">
            <Menu>
            <MenuButton className="navicon " >
              <p className="flex flex-row navicon p-2 font-ubuntu  justify-center items-center gap-2">Funding Programs<AiOutlineDown/> </p>
            </MenuButton>
            <MenuList>
              <NavLink className=" font-ubuntu" to="/" onClick={iedcRedirect}>
              <MenuItem>
                NewGen IEDC
              </MenuItem>

              </NavLink>
              <MenuItem isDisabled>
                SISFS
              </MenuItem>
                {/* <NavLink className=" font-ubuntu" to="/">
              <MenuItem isDisabled>
                 <NavLink className=" font-ubuntu" to="/DEI-EIR"> 
                DEI-EIR
              </MenuItem>
                </NavLink> */}
              
            </MenuList>
            </Menu>
            </li>

            
            <li className="inline-block">
              <NavLink className="navicon p-2 font-ubuntu" to="/IIC">
                IIC
              </NavLink>
            </li>
            <li className="inline-block">
              <NavLink className="navicon p-2 font-ubuntu" to="/event">
                Events
              </NavLink>
            </li>
            <li className="inline-block">
              <NavLink className="navicon p-2 font-ubuntu" to="/mentors">
                Mentors
              </NavLink>
            </li>
            <li className="inline-block">
              <NavLink className="navicon p-2 font-ubuntu" to="/team">
                Team
              </NavLink>
            </li>
            <li className="inline-block">
              <NavLink className="navicon p-2 font-ubuntu" to="/cources">
                Courses
              </NavLink>
            </li>
            <li className="inline-block">
              <button className="hidden lg:block button font-semibold text-xl bg-primaryYellow h-fit p-2 rounded-md md:ml-12 ml-6" onClick={() => {window.open("https://srmdei.accubate.app/ext/form/1600/1/apply")}}>
                Apply Now
              </button>
            </li>
          </ul>
          
          <div className="md:hidden mx-6">
            <Button ref={btnRef} colorScheme='yellow'  onClick={onOpen}>
              <RxHamburgerMenu/>
            </Button>
          </div>
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            
          >
            <DrawerOverlay />
            <DrawerContent bg="rgb(35,35,35)">
              <DrawerCloseButton bg="white"/>
              <DrawerHeader color="white"><h1>Directorate of Entrepreneurship and Innovation</h1></DrawerHeader>

              <DrawerBody>
                <ul className=" ">
                  <li className="my-2">
                    <NavLink className=" text-white text-xl my-2 font-ubuntu" to="/">
                    Home
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl py-2 font-ubuntu" to="/about">
                      About
                    </NavLink>
                  
                  </li>
                  <Divider/>
                  
                  <li className="my-2">
                  
                    <NavLink className="text-white text-xl py-2  font-ubuntu" to="/" onClick={fablabRedirect}>
                    
                      FABLAB
                    
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl py-2  font-ubuntu" to="/" onClick={ciapRedirect}>
                      CIAP
                    </NavLink>
                  </li>

                  <Divider />

                  <li className="my-2" >
                    <NavLink className="text-white text-xl py-2 font-ubuntu" to="/" onClick={iedcRedirect}>
                      NewGen IEDC
                    </NavLink>
                  </li>
                  
                  
                  <Divider />
                  <li className="inline-block">
                    <NavLink className="text-white text-xl py-2 font-ubuntu" to="/IIC">
                      IIC
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl py-2   font-ubuntu" to="/event">
                      Events
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl py-2   font-ubuntu" to="/mentors">
                      Mentors
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl py-2  font-ubuntu" to="/team">
                      Team
                    </NavLink>
                  </li>
                  <li className="my-2">
                    <NavLink className="text-white text-xl  font-ubuntu" to="/cources">
                      Courses
                    </NavLink>
                  </li>
                </ul>
              </DrawerBody>

              <DrawerFooter>
              <Button bg="yellow.500" className="hidden lg:block button font-semibold text-xl bg-primaryYellow h-fit p-2 mt-3 rounded-md md:ml-12 ml-6" onClick={() => {window.open("https://srmdei.accubate.app/ext/form/1600/1/apply")}}>
                Apply Now
              </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Navigation;
